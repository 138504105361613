// src/components/Pricing/CheckoutButton.js
import React from 'react';

/**
 * CheckoutButton Component
 * This component renders a button that initiates the Stripe checkout process.
 */
const CheckoutButton = ({ productId }) => {
  return (
    <form action="/api/stripe/create-checkout-session" method="POST">
      <input type="hidden" name="productId" value={productId} />
      <button 
        type="submit"
        className="w-full py-3 px-4 rounded-lg font-medium
          transition-all duration-200
          bg-orange-500 hover:bg-orange-600 text-white" 
      >
        Start Subscription
      </button>
    </form>
  );
};

export default CheckoutButton;
