// src/components/Modals/SettingsModal.js
import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import ModelSelector from '../Common/ModelSelector';

/**
 * SettingsModal component that allows users to configure their chat settings
 * 
 * @param {Object} props Component props
 * @param {string} props.customInstructions Custom instructions for the AI
 * @param {Function} props.setCustomInstructions Function to update custom instructions
 * @param {string} props.currentlySelectedModel Currently selected AI model
 * @param {Function} props.setCurrentlySelectedModel Function to update selected model
 * @param {Function} props.onSave Function to handle saving settings
 * @param {Function} props.onClose Function to close the modal
 * @param {Function} props.onDeleteThread Function to delete the current thread
 * @param {string} props.sidebarPosition Current position of the sidebar ('left' or 'right')
 * @param {Function} props.setSidebarPosition Function to update the sidebar position
 */
const SettingsModal = ({
  customInstructions,
  setCustomInstructions,
  currentlySelectedModel,
  setCurrentlySelectedModel,
  onSave,
  onClose,
  onDeleteThread,
  sidebarPosition,
  setSidebarPosition,
}) => {
  const [localCustomInstructions, setLocalCustomInstructions] = useState(customInstructions);
  const [localSidebarPosition, setLocalSidebarPosition] = useState(sidebarPosition);

  useEffect(() => {
    setLocalCustomInstructions(customInstructions);
    setLocalSidebarPosition(sidebarPosition);
  }, [customInstructions, sidebarPosition]);

  /**
   * Handles saving the settings and closing the modal
   */
  const handleSave = () => {
    console.log('SettingsModal: State before saving:', {
      customInstructions: localCustomInstructions,
      currentlySelectedModel,
      sidebarPosition: localSidebarPosition,
    });
    
    onSave({
      customInstructions: localCustomInstructions,
      currentlySelectedModel,
      sidebarPosition: localSidebarPosition,
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
      <div className="bg-[#1a1a1a] p-6 rounded-lg w-96 relative border border-[#2a2a2a] shadow-xl">
        <h2 className="text-xl mb-4 text-white">Chat Settings</h2>
        
        <div className="relative mb-4 h-32">
          <textarea
            value={localCustomInstructions}
            onChange={(e) => setLocalCustomInstructions(e.target.value)}
            placeholder="Enter custom instructions..."
            className="w-full h-full p-2 bg-[#2a2a2a] text-gray-200 rounded 
                     hover:bg-[#333333] transition-colors resize-none overflow-y-auto 
                     scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-[#4a4a4a] 
                     scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full
                     focus:outline-none focus:ring-1 focus:ring-orange-500"
          />
        </div>

        {/* Sidebar Position Toggle */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-300 mb-3">
            Sidebar Position
          </label>
          <div className="flex items-center justify-center p-1 bg-[#2a2a2a] rounded-lg">
            <button
              onClick={() => setLocalSidebarPosition('left')}
              className={`flex-1 px-4 py-2.5 rounded-md transition-all duration-200 flex items-center justify-center gap-2
                ${localSidebarPosition === 'left' 
                  ? 'bg-orange-500 text-white shadow-lg' 
                  : 'bg-transparent text-gray-300 hover:bg-[#333333]'
                }`}
            >
              <svg 
                className="w-4 h-4" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8 21V3"/>
              </svg>
              Left
            </button>
            <button
              onClick={() => setLocalSidebarPosition('right')}
              className={`flex-1 px-4 py-2.5 rounded-md transition-all duration-200 flex items-center justify-center gap-2
                ${localSidebarPosition === 'right' 
                  ? 'bg-orange-500 text-white shadow-lg' 
                  : 'bg-transparent text-gray-300 hover:bg-[#333333]'
                }`}
            >
              <svg 
                className="w-4 h-4" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM16 21V3"/>
              </svg>
              Right
            </button>
          </div>
        </div>

        <button
          onClick={handleSave}
          className="w-full bg-orange-500 text-white p-2.5 rounded mb-3
                   hover:bg-orange-600 transition-colors font-medium
                   shadow-lg shadow-orange-500/20"
        >
          Save
        </button>

        <button
          onClick={onClose}
          className="w-full bg-[#2a2a2a] text-gray-200 p-2.5 rounded
                   hover:bg-[#333333] transition-colors font-medium"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SettingsModal;
