import React, { useState } from 'react';
import { X } from 'lucide-react';

/**
 * EditThreadModal component for editing thread names and deleting threads
 * @param {Object} props - Component props
 * @param {string} props.threadId - ID of the thread being edited
 * @param {string} props.currentName - Current name of the thread
 * @param {function} props.onSave - Function to save the new thread name
 * @param {function} props.onClose - Function to close the modal
 * @param {function} props.onDeleteThread - Function to delete the thread
  * @param {function} props.handleThreadChange - Function to handle thread changes
 * @param {Array} props.threadOrder - Array of thread ids
  *  @param {string} props.currentThreadId - ID of the currently selected thread
 */
const EditThreadModal = ({ 
    threadId, 
    currentName, 
    onSave, 
    onClose, 
    onDeleteThread,
    handleThreadChange,
    threadOrder,
    currentThreadId
}) => {
    const [threadName, setThreadName] = useState(currentName);
    const [isDeleting, setIsDeleting] = useState(false);

    /**
     * Handles saving the new thread name
     * Calls onSave function with threadId and trimmed threadName if it's not empty
     */
    const handleSave = () => {
        if (threadName.trim()) {
            onSave(threadId, threadName.trim());
        }
    };

   /**
     * Handles deleting the thread
     * Calls onDeleteThread function with threadId and then closes the modal
     * Ensures proper thread switching after deletion
     */
    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            // Get next thread ID before deletion
            const currentIndex = threadOrder.indexOf(threadId);
            const nextThreadId = currentIndex < threadOrder.length - 1 
                ? threadOrder[currentIndex + 1]
                : threadOrder[currentIndex - 1];

            // Delete current thread
            await onDeleteThread(threadId);
            
            // If this was the current thread and we have a next thread
            if (threadId === currentThreadId && nextThreadId) {
                // Force load the next thread's content
                await handleThreadChange(nextThreadId, true);
            }
            
            onClose();
        } catch (error) {
            console.error('Error during thread deletion:', error);
        } finally {
            setIsDeleting(false);
        }
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
            <div className="bg-[#3a3a3a] p-6 rounded-lg w-96 relative">
                <h2 className="text-xl mb-4">Edit Thread Name</h2>
                <input
                    type="text"
                    value={threadName}
                    onChange={(e) => setThreadName(e.target.value)}
                    className="w-full p-2 bg-gray-700 rounded mb-4"
                    placeholder="Enter new thread name"
                />
                <div className="flex justify-between items-center">
                    <button
                        onClick={handleDelete}
                        disabled={isDeleting}
                        className={`
                            bg-red-500 text-white p-2 rounded 
                            ${isDeleting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'} 
                            transition-colors flex items-center
                        `}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete Thread'}
                    </button>
                    <div className="flex space-x-2">
                        <button
                            onClick={handleSave}
                            disabled={isDeleting}
                            className={`
                                bg-orange-500 text-white p-2 rounded 
                                ${isDeleting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-600'} 
                                transition-colors
                            `}
                        >
                            Save
                        </button>
                        <button
                            onClick={onClose}
                            disabled={isDeleting}
                            className={`
                                bg-gray-500 text-white p-2 rounded 
                                ${isDeleting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'} 
                                transition-colors
                            `}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditThreadModal;
