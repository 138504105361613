// src/components/Common/ExperimentalTooltip.js
import React from 'react';

/**
 * ExperimentalTooltip component displays a tooltip indicating whether experimental features are supported for the current AI model.
 * It provides information about enabled capabilities (internet access, image generation) and a note about increased token usage.
 * If not supported, it shows a message indicating the unsupported model.
 */
const ExperimentalTooltip = ({ isSupported, currentModel }) => {
  return (
    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 w-64 p-2 bg-[#2a2a2a] text-sm text-white rounded-lg shadow-lg z-50"> {/* Changed bg color to match LandingPage */}
      <div className="relative">
        <div className="text-xs leading-tight">
          <p className="font-semibold mb-1 text-orange-500">Experimental Features</p> {/* Changed text color to orange */}
          {isSupported ? (
            <>
              <p className="text-gray-300 mb-1">Enables additional AI capabilities:</p>
              <ul className="list-disc list-inside text-gray-300 space-y-1">
                <li>Internet access for real-time data</li>
                <li>Image generation abilities</li>
              </ul>
              <p className="text-gray-400 mt-1 text-[10px]">Note: Increases token usage</p>
            </>
          ) : (
            <p className="text-gray-300">
              Experimental features are not available with the current model
            </p>
          )}
        </div>
        <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-t-[6px] border-t-[#2a2a2a]"></div> {/* Changed border color to match LandingPage */}
      </div>
    </div>
  );
};

export default ExperimentalTooltip;
