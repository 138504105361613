import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-[#1a1a1a] text-white">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="flex items-center mb-12">
          <Link to="/" className="flex items-center group">
            <img src="/output.webp" alt="Logo" className="h-8 w-8 mr-2" />
            <span className="text-xl font-bold group-hover:text-orange-500 transition-colors">henosis</span>
          </Link>
        </div>

        <div className="space-y-10">
          <div>
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-gray-400">Last updated: {new Date().toLocaleDateString()}</p>
          </div>

          <div className="prose prose-invert max-w-none">
            <section className="mb-10">
              <p className="text-gray-300 text-lg">
                Henosis ("we", "us", "our") operates the Henosis platform and website 
                (collectively, the "Services"). This Privacy Policy explains how we collect, 
                use, disclose, and safeguard your information when you use our Services.
                By using our Services, you agree to the collection and use of information 
                in accordance with this policy.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Information We Collect</h2>
              
              <h3 className="text-xl font-semibold mb-4">Information You Provide</h3>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>
                  <span className="font-medium">Account Information:</span> Email address, 
                  username, and password when you create an account
                </li>
                <li>
                  <span className="font-medium">Usage Data:</span> Your interactions with 
                  our Services, including chat conversations, uploaded files, and generated content
                </li>
                <li>
                  <span className="font-medium">Payment Information:</span> Processed through 
                  secure third-party payment processors (Stripe). We do not store your full payment 
                  information on our servers
                </li>
                <li>
                  <span className="font-medium">Communication Data:</span> Information you 
                  provide when contacting support or responding to surveys
                </li>
              </ul>

              <h3 className="text-xl font-semibold mt-8 mb-4">Information Collected Automatically</h3>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>
                  <span className="font-medium">Technical Data:</span> IP address, browser 
                  type, device information, and cookies
                </li>
                <li>
                  <span className="font-medium">Usage Analytics:</span> How you interact 
                  with our Services, features used, and time spent
                </li>
                <li>
                  <span className="font-medium">Performance Data:</span> Technical 
                  performance metrics and error logs
                </li>
              </ul>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">How We Use Your Information</h2>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>Provide and maintain our Services</li>
                <li>Process your transactions</li>
                <li>Improve our Services and user experience</li>
                <li>Communicate with you about updates and support</li>
                <li>Detect and prevent fraud or abuse</li>
                <li>Comply with legal obligations</li>
                <li>Monitor and analyze usage patterns and trends</li>
              </ul>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Data Processing and AI Models</h2>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>We use multiple AI providers (including but not limited to Anthropic, OpenAI, 
                    Google, and others) to process your queries</li>
                <li>Your interactions are processed by these models but are not used for model training</li>
                <li>We maintain data separation and security standards with all AI providers</li>
                <li>Chat history and user data are stored on our secure servers</li>
              </ul>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Data Security</h2>
              <p className="text-gray-300 mb-4">
                We implement security measures to protect your data, including:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>Encryption of data at rest on our servers</li>
                <li>Regular security assessments</li>
                <li>Access controls and monitoring</li>
                <li>Secure data handling procedures</li>
              </ul>
              <p className="text-gray-300 mt-4">
                While we implement safeguards, no method of transmission over the internet or 
                electronic storage is 100% secure. We cannot guarantee absolute security of your data.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Data Sharing and Third Parties</h2>
              <p className="text-gray-300 mb-4">
                We may share your information with:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>AI model providers for query processing</li>
                <li>Payment processors for transactions (Stripe)</li>
                <li>Service providers helping operate our platform</li>
                <li>Law enforcement when required by law</li>
              </ul>
              <p className="text-gray-300 mt-4">
                We do not sell your personal information or chat history to third parties.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Your Rights and Choices</h2>
              <p className="text-gray-300 mb-4">
                You have the right to:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>Access your personal information</li>
                <li>Request deletion of your data</li>
                <li>Opt-out of marketing communications</li>
                <li>Export your chat history</li>
                <li>Close your account</li>
              </ul>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Children's Privacy</h2>
              <p className="text-gray-300">
                Our Services are not intended for children under 13 years of age. We do not 
                knowingly collect personal information from children under 13. If you are under 13, 
                please do not use our Services or provide any personal information to us.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Changes to This Privacy Policy</h2>
              <p className="text-gray-300">
                We may update this Privacy Policy from time to time. We will notify you of any 
                changes by posting the new Privacy Policy on this page and updating the "Last 
                updated" date. You are advised to review this Privacy Policy periodically for 
                any changes.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Cookies and Tracking</h2>
              <p className="text-gray-300">
                We use cookies and similar tracking technologies to track activity on our Services 
                and hold certain information. Cookies are files with small amount of data that may 
                include an anonymous unique identifier. You can instruct your browser to refuse all 
                cookies or to indicate when a cookie is being sent.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">International Data Transfers</h2>
              <p className="text-gray-300">
                Your information may be transferred to and processed in countries other than 
                your country of residence. These countries may have different data protection laws. 
                By using our Services, you consent to this transfer.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
              <p className="text-gray-300">
                If you have any questions about this Privacy Policy, please contact us at:{' '}
                <a href="mailto:henosischat@gmail.com" 
                   className="text-orange-500 hover:text-orange-400 transition-colors">
                  henosischat@gmail.com
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
