// src/config/index.js
const config = {
    development: {
      baseUrl: 'http://localhost:8081',
      apiUrl: 'http://localhost:8081/api',
      wsUrl: 'ws://localhost:8081',
    },
    production: {
      baseUrl: 'https://henosis.us',
      apiUrl: 'https://henosis.us/api',
      wsUrl: 'wss://henosis.us',
    }
  }[process.env.NODE_ENV || 'development'];
  
  export default config;
  