import React, { useState, useEffect, useCallback } from 'react';
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom';
import ChatContainer from './components/Chat/ChatContainer';
import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import Dashboard from './components/Dashboard';
import ProjectsPage from './components/Projects/ProjectsPage';
import CreateProjectPage from './components/Projects/CreateProjectPage';
import PricingPage from './components/Pricing/PricingPage';
import PasswordReset from './components/PasswordReset';
import LandingPage from './components/Landing/LandingPage';
import useAuth from './hooks/useAuth';
import config from './config';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import TermsOfService from './components/Legal/TermsOfService';

/**
 * Main App component handling routing and authentication
 */
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [subscriptionTier, setSubscriptionTier] = useState('free');
  const navigate = useNavigate();

  // Initialize auth hook with logout handler
  const { verifyAuth } = useAuth(() => {
    setIsLoggedIn(false);
    setSubscriptionTier('free');
    navigate('/login');
  });

  // Initial auth check and periodic verification
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/check-auth`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIsLoggedIn(true);
          setSubscriptionTier(data.subscriptionTier || 'free');
          
          // Only navigate if we're on the login page
          if (window.location.pathname === '/login') {
            navigate('/chat', { replace: true });
          }
        } else {
          // If auth check fails, try device-based refresh
          const deviceId = localStorage.getItem('deviceId');
          const deviceName = localStorage.getItem('deviceName');
          
          if (deviceId && deviceName) {
            const refreshResponse = await fetch(`${config.apiUrl}/refresh-from-device`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ device_id: deviceId })
            });

            if (refreshResponse.ok) {
              const refreshData = await refreshResponse.json();
              setIsLoggedIn(true);
              setSubscriptionTier(refreshData.subscriptionTier || 'free');
              
              // Only navigate if we're on the login page
              if (window.location.pathname === '/login') {
                navigate('/chat', { replace: true });
              }
              return;
            }
          }

          // If both checks fail, ensure we're logged out
          setIsLoggedIn(false);
          setSubscriptionTier('free');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        setIsLoggedIn(false);
        setSubscriptionTier('free');
      } finally {
        setIsAuthChecked(true);
      }
    };

    checkAuthStatus();
    const authInterval = setInterval(checkAuthStatus, 1800000); // 30 minutes

    return () => clearInterval(authInterval);
  }, [navigate]);

  const handleLogin = useCallback(async (loginData) => {
    try {
      console.log('Login successful:', loginData);
      setIsLoggedIn(true);
      setSubscriptionTier(loginData.subscriptionTier || 'free');
      
      // Store device info on successful login
      if (loginData.device_id && loginData.device_name) {
        localStorage.setItem('deviceId', loginData.device_id);
        localStorage.setItem('deviceName', loginData.device_name);
      }
      
      navigate('/chat', { replace: true });
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred during login. Please try again.');
    }
  }, [navigate]);

  const handleRegister = useCallback(async (registrationData) => {
    try {
      console.log('Registration successful:', registrationData);
      setIsLoggedIn(true);
      setSubscriptionTier('free'); // New users start with free tier
      
      // Store device info for new registrations
      if (registrationData.device_id && registrationData.device_name) {
        localStorage.setItem('deviceId', registrationData.device_id);
        localStorage.setItem('deviceName', registrationData.device_name);
      }
      
      navigate('/chat', { replace: true });
    } catch (error) {
      console.error('Registration error:', error);
      alert('An error occurred during registration. Please try again.');
    }
  }, [navigate]);

  const handleLogout = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiUrl}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_id: localStorage.getItem('deviceId')
        })
      });

      if (response.ok) {
        setIsLoggedIn(false);
        setSubscriptionTier('free');
        // Don't clear device info on logout to maintain device recognition
        navigate('/login', { replace: true });
      } else {
        console.error('Failed to logout.');
        alert('Failed to logout. Please try again.');
      }
    } catch (error) {
      console.error('Logout error:', error);
      alert('An error occurred during logout. Please try again.');
    }
  }, [navigate]);

  // Add debug logging
  useEffect(() => {
    console.log('Auth state changed:', {
      isLoggedIn,
      isAuthChecked,
      currentPath: window.location.pathname
    });
  }, [isLoggedIn, isAuthChecked]);

  if (!isAuthChecked) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#1a1a1a] text-white">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <Routes>
      {/* Landing Page Route - Accessible without auth */}
      <Route
        path="/landing"
        element={<LandingPage />}
      />

      {/* Public Routes */}
      <Route
        path="/login"
        element={
          isLoggedIn ? (
            <Navigate to="/chat" replace />
          ) : (
            <LoginForm 
              onLogin={handleLogin} 
              onSwitchToRegister={() => navigate('/register')}
            />
          )
        }
      />
      <Route
        path="/register"
        element={
          isLoggedIn ? (
            <Navigate to="/chat" replace />
          ) : (
            <RegistrationForm 
              onRegister={handleRegister} 
              onSwitchToLogin={() => navigate('/login')}
            />
          )
        }
      />
      <Route
        path="/reset-password"
        element={
          isLoggedIn ? (
            <Navigate to="/chat" replace />
          ) : (
            <PasswordReset 
              onLogin={handleLogin}
              onSwitchToRegister={() => navigate('/register')}
            />
          )
        }
      />
      <Route
        path="/privacy"
        element={<PrivacyPolicy />}
      />
      <Route
        path="/terms"
        element={<TermsOfService />}
      />

      {/* Pricing Routes - Accessible to both logged in and non-logged in users */}
      <Route
        path="/pricing"
        element={
          <PricingPage 
            currentTier={subscriptionTier}
            isLoggedIn={isLoggedIn}
            onUpgrade={(tier) => {
              if (!isLoggedIn) {
                navigate('/login');
              }
              // Handle upgrade logic
            }}
          />
        }
      />

      {/* Protected Routes */}
      <Route
        path="/chat"
        element={
          isLoggedIn ? (
            <ChatContainer 
              onLogout={handleLogout}
              subscriptionTier={subscriptionTier} 
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/dashboard"
        element={
          isLoggedIn ? (
            <Dashboard 
              onLogout={handleLogout}
              subscriptionTier={subscriptionTier}
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/projects"
        element={
          isLoggedIn ? (
            <ProjectsPage 
              onLogout={handleLogout}
              subscriptionTier={subscriptionTier}
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/projects/new"
        element={
          isLoggedIn ? (
            <CreateProjectPage subscriptionTier={subscriptionTier} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/projects/:projectId"
        element={
          isLoggedIn ? (
            <ChatContainer 
              onLogout={handleLogout}
              isProjectWorkspace={true}
              subscriptionTier={subscriptionTier}
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/shared/:threadId"
        element={
          <ChatContainer 
            onLogout={handleLogout}
            subscriptionTier={subscriptionTier}
            isLoggedIn={isLoggedIn}
            isSharedThread={true} // New prop to indicate shared thread context
          />
        }
      />
      {/* Root and Catch-All Routes */}
      <Route
        path="/"
        element={
          isLoggedIn ? (
            <Navigate to="/chat" replace />
          ) : (
            <Navigate to="/landing" replace />
          )
        }
      />
      <Route
        path="*"
        element={<Navigate to={isLoggedIn ? "/chat" : "/login"} replace />}
      />
    </Routes>
  );
};

export default App;
