// src/components/Projects/CreateProjectPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectsHeader from './ProjectsHeader';
import ModelSelector from '../Common/ModelSelector';
import config from '../../config';
/**
 * Full page component for creating new projects
 * Handles form validation and submission according to backend requirements
 */
const CreateProjectPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    model: 'claude-3-5-sonnet-20241022',
    customInstructions: '',
    experimentalFeatures: false
  });

  // Handle all form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Handle model selection from ModelSelector component
  const handleModelSelect = (model) => {
    setFormData(prev => ({ ...prev, model }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      const response = await fetch(`${config.apiUrl}/projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      navigate(`/projects/${data.id}`);
    } catch (err) {
      setError('Failed to create project. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-[#1a1a1a]">
      <ProjectsHeader />
      
      <div className="flex-1 p-6 overflow-auto">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-6">Create New Project</h1>
          
          {error && (
            <div className="bg-red-500 text-white p-3 rounded mb-4">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-white mb-2">Project Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                minLength={3}
                maxLength={100}
                className="w-full p-2 bg-gray-700 rounded"
                placeholder="Enter project name"
              />
            </div>

            <div>
              <label className="block text-white mb-2">Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                maxLength={1000}
                className="w-full p-2 bg-gray-700 rounded h-24 resize-none"
                placeholder="Enter project description (optional)"
              />
            </div>

            <div>
              <label className="block text-white mb-2">Model</label>
              <ModelSelector
                selectedModel={formData.model}
                onSelectModel={handleModelSelect}
              />
            </div>

            <div>
              <label className="block text-white mb-2">Custom Instructions</label>
              <textarea
                name="customInstructions"
                value={formData.customInstructions}
                onChange={handleChange}
                maxLength={5000}
                className="w-full p-2 bg-gray-700 rounded h-32 resize-none"
                placeholder="Enter custom instructions (optional)"
              />
            </div>

            <div>
              <label className="flex items-center text-white">
                <input
                  type="checkbox"
                  name="experimentalFeatures"
                  checked={formData.experimentalFeatures}
                  onChange={handleChange}
                  className="mr-2"
                />
                Enable experimental features
              </label>
            </div>

            <div className="flex gap-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex-1 bg-orange-500 text-white p-2 rounded hover:bg-orange-600 transition-colors disabled:bg-orange-700"
              >
                {isSubmitting ? 'Creating...' : 'Create Project'}
              </button>
              
              <button
                type="button"
                onClick={() => navigate('/projects')}
                className="flex-1 bg-gray-500 text-white p-2 rounded hover:bg-gray-600 transition-colors"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectPage;
