// src/components/Common/ModelSelector.js

import React from 'react';

const ModelSelector = ({ models, selectedModel, onSelectModel }) => {
  return (
    <div className="relative">
      <label className="block text-sm font-medium mb-2">Select Model</label>
      <select
        value={selectedModel}
        onChange={(e) => onSelectModel(e.target.value)}
        className="block w-full bg-gray-700 text-white rounded px-2 py-1"
      >
        {models.map(model => (
          <option key={model.value} value={model.value}>
            {model.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ModelSelector;