// ChatContainer.js
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import ThreadSidebar from '../Sidebar/ThreadSidebar';
import ChatWindow from './ChatWindow';
import SettingsModal from '../Modals/SettingsModal';
import EditThreadModal from '../Modals/EditThreadModal';
import ErrorPopup from '../Common/ErrorPopup';
import ErrorBoundary from '../ErrorBoundary';
import useAuth from '../../hooks/useAuth';
import useChat from '../../hooks/useChat';
import useThreads from '../../hooks/useThreads';
import usePastes from '../../hooks/usePastes';
import useSettings from '../../hooks/useSettings';
import config from '../../config';
import ReactDOM from 'react-dom';

/**
 * ChatContainer component that manages the main chat interface.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onLogout - Callback function for logging out
 */
const ChatContainer = ({ onLogout }) => {
  const navigate = useNavigate();
  const { verifyAuth } = useAuth(onLogout);
  const listRef = useRef(null);
  const userMenuContainerRef = useRef(null);

  // Settings Management
  const {
    customInstructions,
    setCustomInstructions,
    currentlySelectedModel,
    setCurrentlySelectedModel,
    isSettingsOpen,
    handleSettingsToggle,
    handleSaveSettings,
    handleExperimentalFeaturesToggle,
    fetchSettings,
    error: settingsError,
    setError: setSettingsError,
    experimentalFeatures,
    setExperimentalFeatures,
    sidebarPosition,        // Use this from the hook
    setSidebarPosition,     // Use this from the hook
  } = useSettings({ onLogout, defaultModel: 'gemini-1.5-flash-8b' });

  // Paste Management
  const {
    pastes,
    setPastes,
    handleDeletePaste,
    handleFileUpload,
    handleAddPaste,
  } = usePastes();

  // Thread Management
  const {
    threads,
    setThreads,
    threadOrder,
    setThreadOrder,
    currentThreadId,
    setCurrentThreadId,
    currentThreadIndex,
    isLoading: threadsLoading,
    error: threadError,
    hasMore,
    handleThreadChange,
    handleDeleteThread,
    handleUpdateThreadName,
    loadMoreThreads,
    handleNewThread
  } = useThreads({ onLogout, defaultModel: 'gemini-1.5-flash-latest' });

  // Chat State Management
  const {
    messages,
    setMessages,
    inputMessage,
    setInputMessage,
    isGenerating,
    isLoading,
    handleSendMessage,
    handleStopGeneration,
    error: chatError,
    setError: setChatError
  } = useChat({
    threads,
    setThreads,
    threadOrder,
    setThreadOrder,
    currentThreadId,
    setCurrentThreadId,
    onLogout,
    customInstructions,
    pastes,
    setPastes,
    currentlySelectedModel,
  });

  // UI State
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHoverControlled, setIsHoverControlled] = useState(false); // set to false by default

  // Shared Thread State
  const [isSharedThread, setIsSharedThread] = useState(false);
  const [sharedThreadData, setSharedThreadData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const sharedId = params.get('share');
    
  /**
   * Wrapper function to change threads and update the chat state.
   * @param {string} threadId - The ID of the new thread
   */
    // Moved this to before the useEffect that uses it
  const handleThreadChangeWrapper = useCallback((threadId, needsLoad = false) => {
    console.log('ChatContainer: Switching to thread:', { threadId, needsLoad });
    
    if (needsLoad) {
      handleThreadChange(threadId);
    } else {
      setCurrentThreadId(threadId);
      const newThread = threads?.get(threadId);
      if (!newThread) {
        console.error(`ChatContainer: Thread with ID ${threadId} does not exist`);
        return;
      }
      
      setInputMessage('');
      setPastes([]);
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    }
  }, [handleThreadChange, threads, setCurrentThreadId, setInputMessage, setPastes]);

  // Effect to handle shared thread loading
  useEffect(() => {
    const loadSharedThread = async () => {
      if (!sharedId) return;

      try {
        const response = await fetch(`${config.apiUrl}/api/shared/${sharedId}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          setChatError('Shared thread not found or no longer available');
          return;
        }

        const data = await response.json();
        setSharedThreadData(data);
        setIsSharedThread(true);

        // If user is logged in and thread was copied to their account
        if (data.thread_id) {
          handleThreadChangeWrapper(data.thread_id);
        } else {
          // For non-logged in users, display the shared content
          setMessages(data.messages || []);
        }
      } catch (error) {
        console.error('Error loading shared thread:', error);
        setChatError('Error loading shared thread');
      }
    };

    loadSharedThread();
  }, [sharedId, setChatError, setMessages, handleThreadChangeWrapper]);

  // Modified message handler for shared threads
  const handleSharedThreadMessage = useCallback(async (message) => {
    if (!verifyAuth()) {
      // Show login prompt for non-authenticated users
      navigate('/login', { 
        state: { 
          returnUrl: `/chat?share=${sharedId}`,
          message: "Create an account to continue this conversation" 
        } 
      });
      return;
    }
    
    // Normal message handling for authenticated users
    handleSendMessage(message);
  }, [verifyAuth, navigate, sharedId, handleSendMessage]);

  // Effect to verify authentication and fetch settings
  useEffect(() => {
    console.log('ChatContainer: useEffect - Verifying auth and fetching settings');
    verifyAuth();
    fetchSettings();
  }, [verifyAuth, fetchSettings]);

    // Handle mouse enter event to open the sidebars
  // removing the hover effect
    const handleMouseEnterSidebar = useCallback(() => {}, []);

    // Handle mouse leave events
  // removing the hover effect
   const handleMouseLeaveSidebar = useCallback(() => {}, []);

  // Handle sidebar toggles
  const handleToggleSidebar = useCallback(() => {
    console.log('ChatContainer: Toggling sidebar');
    setIsHoverControlled(false);
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  /**
   * Creates a new chat thread and resets the chat state.
   */
  const handleNewChat = useCallback(async () => {
    console.log('ChatContainer: handleNewChat called');
    try {
      const newThread = await handleNewThread(currentlySelectedModel);
      if (newThread) {
        setInputMessage('');
        setPastes([]);
      }
    } catch (error) {
      console.error('ChatContainer: Failed to create a new chat:', error);
      setChatError('Failed to create a new chat. Please try again.');
    }
  }, [
    handleNewThread,
    currentlySelectedModel,
    setInputMessage,
    setPastes,
    setChatError,
  ]);

  /**
   * Opens the edit thread modal for renaming a thread.
   * @param {string} threadId - The ID of the thread to edit
   */
  const handleEditThread = useCallback((threadId) => {
    console.log('ChatContainer: handleEditThread called', { threadId });
    setEditingThreadId(threadId);
    setIsEditModalOpen(true);
  }, []);

  /**
   * Saves the new thread name and closes the edit modal.
   * @param {string} threadId - The ID of the thread
   * @param {string} newName - The new name for the thread
   */
  const handleSaveThreadName = useCallback(
    (threadId, newName) => {
      console.log('ChatContainer: handleSaveThreadName called', {
        threadId,
        newName,
      });
      handleUpdateThreadName(threadId, newName);
      setIsEditModalOpen(false);
      setEditingThreadId(null);
    },
    [handleUpdateThreadName]
  );

  /**
   * Deletes the current thread and closes settings modal.
   */
  const handleDeleteCurrentThread = useCallback(() => {
    console.log('ChatContainer: handleDeleteCurrentThread called');
    if (currentThreadId) {
      handleDeleteThread(currentThreadId);
      handleSettingsToggle(); // Close the settings modal after deletion
    }
  }, [
    currentThreadId,
    handleDeleteThread,
    handleSettingsToggle,
  ]);

  /**
   * Navigates to the dashboard page.
   */
  const handleDashboardClick = useCallback(() => {
    console.log('ChatContainer: handleDashboardClick called');
    navigate('/dashboard');
  }, [navigate]);

    const renderUserMenuPortal = useCallback((userMenuJSX) => {
        if (!userMenuContainerRef.current) return null;
          return ReactDOM.createPortal(userMenuJSX, userMenuContainerRef.current)
    }, [])

  useEffect(() => {
      if (isSidebarOpen && window.innerWidth <= 450) {
          document.body.classList.add('sidebar-open');
      } else {
          document.body.classList.remove('sidebar-open');
      }

      return () => {
          document.body.classList.remove('sidebar-open');
      };
  }, [isSidebarOpen]);

  return (
    <ErrorBoundary>
      <div className="flex flex-col h-screen bg-[#1a1a1a] text-white relative overflow-hidden">
        {/* User Menu Container */}
        <div 
          ref={userMenuContainerRef} 
          className='fixed w-0 h-0 pointer-events-none z-[10000]'
          style={{
            bottom: 0, // Position at the bottom
            ...(sidebarPosition === 'left' 
              ? { left: '288px' }  // 72px * 4 (sidebar width)
              : { right: '288px' })
          }}
        />
       
        {isSidebarOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-10 sm:hidden"
            onClick={handleToggleSidebar}
          />
        )}
        
        <div className={`
          flex-1 relative flex flex-col
          transition-all duration-300
          ${isSidebarOpen ? 'sm:ml-72' : 'ml-0'}
          ${sidebarPosition === 'right' && isSidebarOpen ? 'sm:mr-72' : 'mr-0'}
        `}>
        
          <Header
            onSettingsToggle={handleSettingsToggle}
            onLogout={onLogout}
            onNewChat={handleNewChat}
            onToggleSidebar={handleToggleSidebar} // Pass onToggleSidebar
            onDashboardClick={handleDashboardClick}
            currentThreadId={currentThreadId}
            currentlySelectedModel={currentlySelectedModel}
            setCurrentlySelectedModel={setCurrentlySelectedModel}
            sidebarPosition={sidebarPosition}         // Add this
            setSidebarPosition={setSidebarPosition}   // Add this
            isSidebarOpen={isSidebarOpen} //Pass the prop here
          />
            
          <div className="flex-grow flex justify-center overflow-hidden relative">
           {/* Left Sidebar - only render if position is left */}
            {sidebarPosition === 'left' && (
              <ThreadSidebar
                position="left"
                threads={threads}
                threadOrder={threadOrder}
                currentThreadId={currentThreadId}
                onThreadChange={handleThreadChangeWrapper}
                onEditThread={handleEditThread}
                onDeleteThread={handleDeleteThread}
                onNewChat={handleNewChat}
                isOpen={isSidebarOpen}
                onMouseLeave={handleMouseLeaveSidebar}
                hasMore={hasMore}
                onLoadMore={loadMoreThreads}
                isLoading={threadsLoading}
                  onToggleSidebar={handleToggleSidebar} // Pass the onToggleSidebar function
                  onLogout={onLogout}
                userMenuContainerRef={userMenuContainerRef}
              />
            )}

          {/* Right Sidebar - only render if position is right */}
            {sidebarPosition === 'right' && (
              <ThreadSidebar
                position="right"
                threads={threads}
                threadOrder={threadOrder}
                currentThreadId={currentThreadId}
                onThreadChange={handleThreadChangeWrapper}
                onEditThread={handleEditThread}
                onDeleteThread={handleDeleteThread}
                onNewChat={handleNewChat}
                isOpen={isSidebarOpen}
                onMouseLeave={handleMouseLeaveSidebar}
                hasMore={hasMore}
                onLoadMore={loadMoreThreads}
                isLoading={threadsLoading}
                  onToggleSidebar={handleToggleSidebar} // Pass the onToggleSidebar function
                   onLogout={onLogout}
                   userMenuContainerRef={userMenuContainerRef}
              />
            )}
          
          <div className={`
              flex flex-col flex-grow min-h-0 transition-all duration-300 
              max-w-[1000px] w-full
              px-2 sm:px-4
              ${isSidebarOpen && sidebarPosition === 'left' ? 'sm:ml-72' : 'ml-0'}
              ${isSidebarOpen && sidebarPosition === 'right' ? 'sm:mr-72' : 'mr-0'}
            `}>
              <ChatWindow
                messages={messages}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                isLoading={isLoading}
                isGenerating={isGenerating}
                onSendMessage={isSharedThread ? handleSharedThreadMessage : handleSendMessage}
                onStopGeneration={handleStopGeneration}
                pastes={pastes}
                onDeletePaste={handleDeletePaste}
                onFileUpload={handleFileUpload}
                onAddPaste={handleAddPaste}
                listRef={listRef}
                currentlySelectedModel={currentlySelectedModel}
                experimentalFeatures={experimentalFeatures}
                onToggleExperimental={handleExperimentalFeaturesToggle}
              />
            </div>
          </div>

          {isSettingsOpen && (
            <SettingsModal
              customInstructions={customInstructions}
              setCustomInstructions={setCustomInstructions}
              currentlySelectedModel={currentlySelectedModel}
              setCurrentlySelectedModel={setCurrentlySelectedModel}
              onSave={handleSaveSettings}
              onClose={handleSettingsToggle}
              onDeleteThread={handleDeleteCurrentThread}
              sidebarPosition={sidebarPosition}
              setSidebarPosition={setSidebarPosition}
            />
          )}

          {isEditModalOpen && (
            <EditThreadModal
                threadId={editingThreadId}
                currentName={threads?.get(editingThreadId)?.name || ''}
                onSave={handleSaveThreadName}
                onClose={() => setIsEditModalOpen(false)}
                onDeleteThread={handleDeleteThread}
                handleThreadChange={handleThreadChange}
                threadOrder={threadOrder}
                currentThreadId={currentThreadId}
            />
          )}

          {(chatError || threadError || settingsError) && (
            <ErrorPopup
              error={chatError || threadError || settingsError}
              onClose={() => {
                setChatError(null);
                setSettingsError(null);
              }}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ChatContainer;
