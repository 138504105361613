import React, { useState, useEffect } from 'react';
import { FaLock, FaCheck } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config';
const LoginForm = ({ onLogin, onSwitchToRegister }) => {
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
    device_id: '',
    device_name: ''
  });
  const [error, setError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  // Generate and retrieve device ID on component mount
  useEffect(() => {
    const getOrCreateDeviceId = () => {
      let deviceId = localStorage.getItem('deviceId');
      if (!deviceId) {
        deviceId = generateDeviceId();
        localStorage.setItem('deviceId', deviceId);
      }
      return deviceId;
    };

    const getOrCreateDeviceName = () => {
      let deviceName = localStorage.getItem('deviceName');
      if (!deviceName) {
        deviceName = getDeviceName();
        localStorage.setItem('deviceName', deviceName);
      }
      return deviceName;
    };

    const deviceId = getOrCreateDeviceId();
    const deviceName = getOrCreateDeviceName();

    setLoginData(prev => ({
      ...prev,
      device_id: deviceId,
      device_name: deviceName
    }));
  }, []);

  const generateDeviceId = () => {
    const platform = navigator.platform;
    const userAgent = navigator.userAgent;
    const timestamp = Date.now();
    const random = Math.random().toString(36).substring(2);
    const combinedString = `${platform}-${userAgent}-${timestamp}-${random}`;
    return btoa(combinedString).replace(/[^a-zA-Z0-9]/g, '').substring(0, 32);
  };

  const getDeviceName = () => {
    const ua = navigator.userAgent;
    let deviceName = 'Unknown Device';

    if (/Windows/.test(ua)) deviceName = 'Windows PC';
    else if (/Macintosh/.test(ua)) deviceName = 'Mac';
    else if (/iPhone/.test(ua)) deviceName = 'iPhone';
    else if (/iPad/.test(ua)) deviceName = 'iPad';
    else if (/Android/.test(ua)) deviceName = 'Android Device';

    return `${deviceName} - ${new Date().toLocaleString()}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${config.apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...loginData,
          trust_device: true // Always trust the device
        }),
        credentials: 'include',
      });

      const responseData = await response.json();
      console.log('Login response:', responseData);

      if (response.ok) {
        // Always store device info on successful login
        localStorage.setItem('deviceId', loginData.device_id);
        localStorage.setItem('deviceName', loginData.device_name);
        onLogin(responseData);
      } else if (responseData.requiresVerification) {
        setIsVerifying(true);
      } else {
        if (responseData.error && typeof responseData.error === 'object') {
          const errorMessages = Object.entries(responseData.error)
            .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
            .join('; ');
          setError(errorMessages || 'Login failed. Please check your credentials.');
        } else {
          setError(responseData.error || 'Login failed. Please check your credentials.');
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred during login. Please try again.');
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${config.apiUrl}/verify_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: loginData.username,
          verification_code: verificationCode,
        }),
        credentials: 'include',
      });

      const data = await response.json();
      if (response.ok) {
        onLogin(data);
      } else {
        setError(data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred during verification. Please try again.');
    }
  };

  const formClasses = "bg-[#2a2a2a] p-8 rounded-lg shadow-xl w-full max-w-md mx-auto border border-[#3a3a3a]";
  const inputClasses = "w-full p-3 mb-4 bg-[#1a1a1a] border border-[#3a3a3a] rounded-lg text-white text-sm focus:border-orange-500 focus:ring-1 focus:ring-orange-500 transition-all duration-200 outline-none";
  const buttonClasses = "w-full py-3 px-4 rounded-lg mb-3 transition-all duration-200 font-medium text-sm";
  const errorClasses = "bg-red-500/10 border border-red-500/50 text-red-400 p-4 rounded-lg mb-4 text-sm";
  const linkClasses = "text-orange-500 hover:text-orange-400 text-sm transition-colors duration-200";

  if (isVerifying) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#1a1a1a] px-4 py-8">
        <form onSubmit={handleVerificationSubmit} className={formClasses}>
          <h2 className="text-2xl font-bold mb-6 text-white text-center">Verify Email</h2>
          {error && <div className={errorClasses}>{error}</div>}
          <input
            type="text"
            name="verificationCode"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className={inputClasses}
            required
          />
          <button 
            type="submit" 
            className={`${buttonClasses} bg-orange-500 hover:bg-orange-600 text-white`}
          >
            Verify
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#1a1a1a] px-4 py-8">
      <form onSubmit={handleSubmit} className={formClasses}>
        <h2 className="text-2xl font-bold mb-6 text-white text-center">Login</h2>
        {error && <div className={errorClasses}>{error}</div>}
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={loginData.username}
          onChange={handleInputChange}
          className={inputClasses}
          autoComplete="username"
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={loginData.password}
          onChange={handleInputChange}
          className={inputClasses}
          autoComplete="current-password"
          required
        />
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <input
              type="checkbox"
              name="trust_device"
              id="trust_device"
              checked={loginData.trust_device}
              onChange={handleInputChange}
              className="w-4 h-4 rounded border-[#3a3a3a] bg-[#1a1a1a] text-orange-500 focus:ring-orange-500 focus:ring-offset-0"
            />
            <label htmlFor="trust_device" className="text-gray-300 text-sm flex items-center ml-2">
              <FaLock className="mr-2 text-orange-500" />
              Trust this device
            </label>
          </div>
          <Link to="/reset-password" className={linkClasses}>
            Forgot Password?
          </Link>
        </div>
        <button 
          type="submit" 
          className={`${buttonClasses} bg-orange-500 hover:bg-orange-600 text-white`}
        >
          Login
        </button>
        <button 
          type="button" 
          onClick={onSwitchToRegister} 
          className={`${buttonClasses} bg-[#3a3a3a] hover:bg-[#404040] text-gray-300 hover:text-white`}
        >
          Register
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
