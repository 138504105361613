// MessageList.js
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import MessageItem from './MessageItem';

const MessageList = ({ messages, shouldAutoScroll }) => {
  const listRef = useRef(null);
  const messageHeightsRef = useRef({});
  const [listKey, setListKey] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isInitialized, setIsInitialized] = useState(false);
  const lastMessageLengthRef = useRef(messages.length);

  const scrollToBottom = useCallback(() => {
    if (listRef.current) {
      const scrollIndex = messages.length - 1;
      
      // First scroll using react-window's method
      listRef.current.scrollToItem(scrollIndex, 'end');
      
      // Then force a complete scroll after a brief delay to ensure all content is rendered
      setTimeout(() => {
        const container = listRef.current._outerRef;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'auto' // Use 'auto' instead of 'smooth' for better performance
          });
        }
      }, 100);
    }
  }, [messages.length]);

  // Improved scroll handling after new messages
  useEffect(() => {
    const needsScroll = shouldAutoScroll || messages.length > lastMessageLengthRef.current;
    lastMessageLengthRef.current = messages.length;

    if (needsScroll && isInitialized) {
      // Initial scroll
      scrollToBottom();
      
      // Double-check scroll position after a delay
      setTimeout(() => {
        const container = listRef.current?._outerRef;
        if (container) {
          const isNotAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight > 100;
          if (isNotAtBottom) {
            scrollToBottom();
          }
        }
      }, 500);
    }
  }, [messages.length, shouldAutoScroll, scrollToBottom, isInitialized]);

  // Add new resize observer for dynamic content
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (shouldAutoScroll) {
        scrollToBottom();
      }
    });

    const container = listRef.current?._outerRef;
    if (container) {
      observer.observe(container);
    }

    return () => observer.disconnect();
  }, [scrollToBottom, shouldAutoScroll]);

  // Reset heights and force recalculation when messages change
  useEffect(() => {
    messageHeightsRef.current = {};
    setListKey(prev => prev + 1);
    
    const timer = setTimeout(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
        
        const hasNewMessage = messages.length > lastMessageLengthRef.current;
        lastMessageLengthRef.current = messages.length;

        if ((hasNewMessage || shouldAutoScroll) && isInitialized) {
          scrollToBottom();
        }
      }
    }, 50);

    if (!isInitialized && messages.length > 0) {
      setIsInitialized(true);
    }

    return () => clearTimeout(timer);
  }, [messages.length, shouldAutoScroll, scrollToBottom, isInitialized]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (listRef.current) {
        messageHeightsRef.current = {};
        listRef.current.resetAfterIndex(0, true);
        setListKey(prev => prev + 1);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setMessageHeight = useCallback((index, height) => {
    const currentHeight = messageHeightsRef.current[index];
    const newHeight = Math.max(height + 24, 100);

    if (currentHeight !== newHeight) {
      messageHeightsRef.current[index] = newHeight;
      
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
        
        requestAnimationFrame(() => {
          listRef.current.resetAfterIndex(0, true);
          
          if (index === messages.length - 1) {
            scrollToBottom();
          }
        });
      }
    }
  }, [messages.length, scrollToBottom]);

  const getItemSize = useCallback((index) => {
    return messageHeightsRef.current[index] || 100;
  }, []);

  const renderRow = useCallback(
    ({ index, style }) => (
      <MessageItem
        index={index}
        style={{
          ...style,
          top: `${parseFloat(style.top) + 12}px`,
          height: `${parseFloat(style.height) - 24}px`
        }}
        data={{
          messages,
          setMessageHeight,
          listRef
        }}
      />
    ),
    [messages, setMessageHeight]
  );

  return (
    <div className="h-full bg-[#1a1a1a]">
      <AutoSizer>
        {({ height, width }) => {
          if (height !== dimensions.height || width !== dimensions.width) {
            setDimensions({ height, width });
          }
          
          return (
            <List
              key={listKey}
              height={height}
              itemCount={messages.length}
              itemSize={getItemSize}
              width={width}
              ref={listRef}
              overscanCount={5}
              className="scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full"
              onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
                requestAnimationFrame(() => {
                  if (listRef.current) {
                    listRef.current.resetAfterIndex(0, true);
                  }
                });
              }}
            >
              {renderRow}
            </List>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default React.memo(MessageList);
