// LoadingBubble.js

import React from 'react';

/**
 * LoadingBubble Component
 * Purpose: Displays an animated loading indicator in the chat interface
 * Renders three bouncing dots to indicate message generation in progress
 * Uses React.memo for performance optimization to prevent unnecessary re-renders
 * 
 * Styling:
 * - Aligned with assistant messages (left side)
 * - Matches the chat bubble appearance
 * - Each dot animates with a slight delay for a wave effect
 */
const LoadingBubble = React.memo(() => (
  <div className="inline-flex items-center space-x-2 bg-[#1a1a1a] p-3 rounded-lg max-w-[50%] ml-4">
    <div
      className="w-2 h-2 bg-white rounded-full animate-bounce"
      style={{ animationDelay: '0s' }}
    ></div>
    <div
      className="w-2 h-2 bg-white rounded-full animate-bounce"
      style={{ animationDelay: '0.2s' }}
    ></div>
    <div
      className="w-2 h-2 bg-white rounded-full animate-bounce"
      style={{ animationDelay: '0.4s' }}
    ></div>
  </div>
));

export default LoadingBubble;
