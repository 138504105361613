import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-[#1a1a1a] text-white">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="flex items-center mb-12">
          <Link to="/" className="flex items-center group">
            <img src="/output.webp" alt="Logo" className="h-8 w-8 mr-2" />
            <span className="text-xl font-bold group-hover:text-orange-500 transition-colors">
              henosis
            </span>
          </Link>
        </div>

        <div className="space-y-10">
          <div>
            <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
            <p className="text-gray-400">Last updated: {new Date().toLocaleDateString()}</p>
          </div>

          <div className="prose prose-invert max-w-none">
            <section className="mb-10">
              <p className="text-gray-300 text-lg">
                Welcome to Henosis. These Terms of Service ("Terms") govern your access to and use 
                of the Henosis website, application, and services (collectively, the "Services"). 
                Please read these Terms carefully before using the Services.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">1. Acceptance of Terms</h2>
              <p className="text-gray-300">
                By accessing or using the Services, you agree to be bound by these Terms. If you 
                disagree with any part of the Terms, you do not have permission to access or use 
                the Services.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">2. Description of Services</h2>
              <p className="text-gray-300 mb-4">
                Henosis provides access to various AI models and services, including but not limited to:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-3">
                <li>AI chat and conversation capabilities</li>
                <li>Document analysis and processing</li>
                <li>Code analysis and generation</li>
                <li>Content summarization and analysis</li>
                <li>File processing and analysis</li>
              </ul>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">3. User Accounts</h2>
              <div className="space-y-4 text-gray-300">
                <p>
                  To access certain features of the Services, you must create an account. You agree to:
                </p>
                <ul className="list-disc pl-6 space-y-3">
                  <li>Provide accurate and complete information</li>
                  <li>Maintain the security of your account credentials</li>
                  <li>Promptly update any changes to your information</li>
                  <li>Accept responsibility for all activities under your account</li>
                </ul>
              </div>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">4. Payments and Subscriptions</h2>
              <div className="space-y-4 text-gray-300">
                <p>
                  Certain features of the Services require payment of fees:
                </p>
                <ul className="list-disc pl-6 space-y-3">
                  <li>Subscription fees are billed in advance on a recurring basis</li>
                  <li>All fees are non-refundable unless required by law</li>
                  <li>You may cancel your subscription at any time</li>
                  <li>Changes to subscription plans take effect at the next billing cycle</li>
                </ul>
              </div>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">5. User Content</h2>
              <div className="space-y-4 text-gray-300">
                <p>
                  You retain ownership of any content you submit to the Services. By submitting 
                  content, you grant Henosis a license to:
                </p>
                <ul className="list-disc pl-6 space-y-3">
                  <li>Store and process your content to provide the Services</li>
                  <li>Generate outputs based on your content</li>
                  <li>Improve our Services (without using your content to train AI models)</li>
                </ul>
              </div>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">6. Acceptable Use</h2>
              <div className="space-y-4 text-gray-300">
                <p>You agree not to:</p>
                <ul className="list-disc pl-6 space-y-3">
                  <li>Use the Services for any illegal purpose</li>
                  <li>Attempt to gain unauthorized access to the Services</li>
                  <li>Interfere with or disrupt the Services</li>
                  <li>Create multiple accounts to avoid usage limits</li>
                  <li>Share account credentials with others</li>
                  <li>Use the Services to generate harmful or malicious content</li>
                </ul>
              </div>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">7. Intellectual Property</h2>
              <p className="text-gray-300">
                The Services, including all software, algorithms, and content (excluding User 
                Content), are owned by Henosis. Nothing in these Terms grants you any right to 
                use our intellectual property.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">8. Disclaimers</h2>
              <div className="space-y-4 text-gray-300">
                <p>
                  THE SERVICES ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY. We do not guarantee:
                </p>
                <ul className="list-disc pl-6 space-y-3">
                  <li>Accuracy or completeness of AI-generated outputs</li>
                  <li>Uninterrupted or error-free service</li>
                  <li>Suitability for any particular purpose</li>
                </ul>
              </div>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">9. Limitation of Liability</h2>
              <p className="text-gray-300">
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, HENOSIS SHALL NOT BE LIABLE FOR ANY 
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS 
                OF PROFITS OR REVENUES.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">10. Termination</h2>
              <p className="text-gray-300">
                We may terminate or suspend your access to the Services at any time, without 
                prior notice, for any reason, including violation of these Terms.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">11. Changes to Terms</h2>
              <p className="text-gray-300">
                We may modify these Terms at any time. We will notify you of material changes 
                by posting the updated Terms on our website. Your continued use of the Services 
                after such changes constitutes acceptance of the new Terms.
              </p>
            </section>

            <section className="mb-10">
              <h2 className="text-2xl font-bold mb-6">12. Contact Information</h2>
              <p className="text-gray-300">
                For questions about these Terms, please contact us at:{' '}
                <a 
                  href="mailto:henosischat@gmail.com" 
                  className="text-orange-500 hover:text-orange-400 transition-colors"
                >
                  henosischat@gmail.com
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
