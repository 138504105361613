// src/hooks/useAuth.js

import { useCallback } from 'react';
import { checkAuth, logModelCall } from '../services/api';

/**
 * Custom hook to manage authentication.
 * @param {Function} onLogout - Function to call on logout.
 * @returns {Object} - Contains a function to verify authentication.
 */
const useAuth = (onLogout) => {
  const verifyAuth = useCallback(async () => {
    try {
      await checkAuth();
    } catch (error) {
      console.error('Authentication verification failed:', error);
      onLogout();
    }
  }, [onLogout]);

  return { verifyAuth };
};

export default useAuth;