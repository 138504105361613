// src/hooks/useThreads.js
import { useState, useCallback, useEffect, useRef } from 'react';
import {
  getConversations,
  saveConversation,
  deleteThread,
  updateThreadName,
  API_BASE_URL
} from '../services/api';

/**
 * Custom hook to manage chat threads and their state
 * Handles thread loading, creation, deletion, and updates
 *
 * @param {Object} params Configuration parameters
 * @param {Function} params.onLogout Callback for handling logout events
 * @param {string} params.defaultModel Default AI model to use for new threads
 * @returns {Object} Thread state and management functions
 */
const useThreads = ({ onLogout, defaultModel }) => {
  const [threads, setThreads] = useState(new Map());
  const [threadOrder, setThreadOrder] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [currentThreadIndex, setCurrentThreadIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState(new Map());
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const loadedThreadsRef = useRef(new Set());
  const threadsRef = useRef(new Map());
  const loadingThreadsRef = useRef(new Set());

    useEffect(() => {
        threadsRef.current = threads;
    }, [threads]);

    const loadThreadContent = useCallback(async (threadId) => {
        if (!threadId || !threads.has(threadId)) {
            console.error('Invalid thread ID or thread not found:', threadId);
            return;
        }
    
        // If already loaded or loading, skip
        if (loadedThreadsRef.current.has(threadId) || 
            loadingThreadsRef.current.has(threadId)) {
            return;
        }
    
        console.log('Loading thread content:', threadId);
        
        try {
            loadingThreadsRef.current.add(threadId);
            setLoadingStates(prev => new Map(prev).set(threadId, true));
    
            if (!String(threadId).startsWith('temp-')) {
                const messages = await getConversations('thread', { 
                    thread_id: threadId 
                });
    
                setThreads(prev => {
                    const updated = new Map(prev);
                    const existingThread = updated.get(threadId);
                    if (existingThread) {
                        updated.set(threadId, {
                            ...existingThread,
                            messages,
                            isLoaded: true
                        });
                    }
                    return updated;
                });
    
                loadedThreadsRef.current.add(threadId);
            }
        } catch (error) {
            console.error('Error loading thread content:', error);
            setError(error.message);
        } finally {
            loadingThreadsRef.current.delete(threadId);
            setLoadingStates(prev => {
                const updated = new Map(prev);
                updated.delete(threadId);
                return updated;
            });
        }
    }, [threads, getConversations]);
  
  
    /**
     * Fetches initial thread data and sets up the thread state
     * Loads the first thread's content automatically
     */
    const fetchConversations = useCallback(async () => {
        console.log('useThreads: Fetching conversations')
        try {
            setIsLoading(true);
            const response = await getConversations('initial', { limit: 50 });
            console.log('useThreads: Initial response:', response);
  
            if (Array.isArray(response) && response.length > 0) {
                const threadMap = new Map();
                response.forEach(thread => {
                    const threadObj = {
                        id: thread.id,
                        name: thread.name,
                        isLoaded: false,
                        messages: thread.messages || [],
                        selectedModel: thread.selectedModel || defaultModel,
                        pasteCounter: thread.pasteCounter,
                        preview: thread.preview
                    };
                    threadMap.set(thread.id, threadObj);
                });
  
                setThreads(threadMap);
                setThreadOrder(response.map(thread => thread.id));
                setOffset(response.length);
                setHasMore(response.length >= 50);
  
                if (response.length > 0) {
                    const firstThreadId = response[0].id;
                    setCurrentThreadId(firstThreadId);
                    setCurrentThreadIndex(0);
                    
                    // Immediately load the first thread's content
                    const firstThreadMessages = await getConversations('thread', { 
                        thread_id: firstThreadId 
                    });
                    
                    // Update the thread with loaded messages
                    threadMap.set(firstThreadId, {
                        ...threadMap.get(firstThreadId),
                        messages: firstThreadMessages,
                        isLoaded: true
                    });
                    setThreads(new Map(threadMap));
                    loadedThreadsRef.current.add(firstThreadId);
                }
            } else {
              setHasMore(false);
            }
        } catch (error) {
            console.error('useThreads: Error fetching conversations:', error);
            setError(error.message);
            if (error.message.includes('401')) {
                onLogout();
            }
        } finally {
            setIsLoading(false);
        }
    }, [onLogout, defaultModel]);
  
  
  
    /**
     * Loads more threads when scrolling
     */
    const loadMoreThreads = useCallback(async () => {
        if (!hasMore || isPaginationLoading) {
            console.log('useThreads: Skipping load more -', {
                hasMore,
                isPaginationLoading,
                currentOffset: offset,
                currentThreadCount: threadOrder.length
            });
            return;
        }

        try {
            setIsPaginationLoading(true);
      
            console.log('useThreads: Loading more threads from offset:', offset);
      
            const response = await getConversations('scroll', { 
                offset,
                limit: 50 
            });

            if (Array.isArray(response) && response.length > 0) {
                // Batch state updates together
                setThreads(prev => {
                    const updated = new Map(prev);
                    const newThreads = [];
          
                    response.forEach(thread => {
                        if (!updated.has(thread.id)) {
                            const threadObj = {
                                id: thread.id,
                                name: thread.name,
                                isLoaded: false,
                                messages: [],
                                selectedModel: thread.selectedModel || defaultModel,
                                pasteCounter: thread.pasteCounter,
                                preview: thread.preview
                            };
                            updated.set(thread.id, threadObj);
                            newThreads.push(thread.id);
                        }
                    });
          
                    console.log('useThreads: Adding new threads:', {
                        existingCount: prev.size,
                        newCount: newThreads.length,
                        totalCount: updated.size
                    });
          
                    return updated;
                });

                setThreadOrder(prev => {
                    // Ensure we don't duplicate thread IDs
                    const existingIds = new Set(prev);
                    const newIds = response
                        .map(thread => thread.id)
                        .filter(id => !existingIds.has(id));
          
                    const updatedOrder = [...prev, ...newIds];
          
                    console.log('useThreads: Updated thread order:', {
                        previousCount: prev.length,
                        newIdsCount: newIds.length,
                        totalCount: updatedOrder.length
                    });
          
                    return updatedOrder;
                });

                // Update offset after successful load
                setOffset(currentOffset => {
                    const newOffset = currentOffset + response.length;
                    console.log('useThreads: Updated offset:', {
                        previous: currentOffset,
                        new: newOffset
                    });
                    return newOffset;
                });
          
                setHasMore(response.length >= 50);

            } else {
                console.log('useThreads: No more threads available');
                setHasMore(false);
            }
        } catch (error) {
            console.error('useThreads: Error loading more threads:', error);
            setError(error.message);
        } finally {
            setIsPaginationLoading(false);
        }
    }, [hasMore, isPaginationLoading, offset, defaultModel, threadOrder.length]);

  /**
   * Creates a new thread with the specified model
   * @param {string} selectedModel - The AI model to use for the new thread
   */
  const handleNewThread = useCallback(async (selectedModel, doSave = true) => {
    console.log('useThreads: Creating new thread with model:', selectedModel);
    try {
      const thread = {
        id: doSave ? null : 'temp-' + Date.now(), // Use temporary ID if not saving
        name: 'New Chat',
        messages: [],
        selectedModel: selectedModel,
        isLoaded: true,
        isTemporary: !doSave // Add flag to track temporary status
      };

      if (doSave) {
        // Only save to backend if doSave is true
        const savedThread = await saveConversation(
          thread.name,
          thread.messages,
          null,
          selectedModel,
          true
        );
        
        setThreads(prev => {
          const updated = new Map(prev);
          updated.set(savedThread.thread_id, {
            ...thread,
            id: savedThread.thread_id,
            name: savedThread.name || thread.name
          });
          return updated;
        });

        setThreadOrder(prev => [savedThread.thread_id, ...prev]);
        setCurrentThreadId(savedThread.thread_id);
        return savedThread;
      } else {
        // Just add to local state if temporary
        setThreads(prev => {
          const updated = new Map(prev);
          updated.set(thread.id, thread);
          return updated;
        });

        setThreadOrder(prev => [thread.id, ...prev]);
        setCurrentThreadId(thread.id);
        return thread;
      }
    } catch (error) {
      console.error('useThreads: Error creating new thread:', error);
      setError('Failed to create new thread');
      return null;
    }
  }, [setThreads, setThreadOrder, setCurrentThreadId, setError]);
  /**
   * Handles thread selection and content loading
   * @param {string} threadId ID of the thread to select
   */
  const handleThreadChange = useCallback(async (threadId, needsLoad = false) => {
        const processedThreadId = typeof threadId === 'string' && !threadId.startsWith('temp-') 
        ? parseInt(threadId, 10) 
        : threadId;

        console.log('useThreads: Changing to thread:', { 
            threadId: processedThreadId, 
            needsLoad,
            threadsSize: threadsRef.current.size,
            hasThread: threadsRef.current.has(processedThreadId),
            isLoaded: loadedThreadsRef.current.has(processedThreadId)
        });

        if (!threadsRef.current.has(processedThreadId)) {
            console.error('useThreads: Invalid thread ID:', processedThreadId);
            return;
        }

        const newIndex = threadOrder.indexOf(processedThreadId);
        setCurrentThreadId(processedThreadId);
        setCurrentThreadIndex(newIndex);

        const thread = threadsRef.current.get(processedThreadId);
    
        // Force reload if needsLoad is true or if thread isn't loaded
        if (needsLoad || !loadedThreadsRef.current.has(processedThreadId)) {
            // Remove from loadedThreadsRef to force a reload
            loadedThreadsRef.current.delete(processedThreadId);
            await loadThreadContent(processedThreadId);
        }
  }, [threadOrder, loadThreadContent]);
  
  
  /**
   * Deletes a thread and updates state
   * @param {string} threadId ID of the thread to delete
   */
    const handleDeleteThread = useCallback(async (threadId) => {
    console.log('useThreads: Starting thread deletion:', {
        threadId,
        currentThreadId,
        totalThreads: threads.size,
        orderLength: threadOrder.length
    });

    try {
        // Only call API delete if it's not a temporary thread
        if (!String(threadId).startsWith('temp-')) {
            await deleteThread(threadId);
        }

        // Find the next thread ID before modifying state
        const currentIndex = threadOrder.indexOf(threadId);
        let nextThreadId = null;

        if (threadOrder.length > 1) {
            if (currentIndex < threadOrder.length - 1) {
                nextThreadId = threadOrder[currentIndex + 1];
            } else {
                nextThreadId = threadOrder[currentIndex - 1];
            }
        }

        // Clear the deleted thread from loaded threads ref
        loadedThreadsRef.current.delete(threadId);
        
        // Update threads map
        setThreads(prev => {
            const updated = new Map(prev);
            updated.delete(threadId);
            return updated;
        });

        // Update thread order
        setThreadOrder(prev => prev.filter(id => id !== threadId));

        // Handle thread switching
        if (currentThreadId === threadId) {
            if (nextThreadId) {
                // Important: Reset ALL loading states and loaded states
                loadingThreadsRef.current.clear(); // Clear all loading states
                loadedThreadsRef.current.clear();  // Clear all loaded states
                
                setCurrentThreadId(nextThreadId);
                setCurrentThreadIndex(
                    threadOrder.indexOf(nextThreadId)
                );

                // Don't immediately load content - let the useEffect handle it
                // This was the key difference in the old implementation
            } else {
                // Create a new temporary thread if this was the last one
                handleNewThread(defaultModel, false);
            }
        }

        console.log('useThreads: Thread deletion completed:', {
            deletedId: threadId,
            nextThreadId,
            remainingThreads: threads.size - 1
        });

    } catch (error) {
        console.error('useThreads: Error deleting thread:', error);
        setError(error.message);
    }
}, [
    currentThreadId, 
    threadOrder, 
    handleNewThread, 
    defaultModel,
    setCurrentThreadId,
    setCurrentThreadIndex
]);

  /**
   * Updates a thread's name
   * @param {string} threadId ID of the thread to update
   * @param {string} newName New name for the thread
   */
  const handleUpdateThreadName = useCallback(async (threadId, newName) => {
    console.log('useThreads: Updating thread name:', { threadId, newName });
    try {
      await updateThreadName(threadId, newName);
      
      setThreads(prev => {
        const updated = new Map(prev);
        const thread = updated.get(threadId);
        if (thread) {
          updated.set(threadId, { ...thread, name: newName });
        }
        return updated;
      });
    } catch (error) {
      console.error('useThreads: Error updating thread name:', error);
      setError(error.message);
    }
  }, []);

    useEffect(() => {
        const initializeThreads = async () => {
            console.log('useThreads: Initializing threads - Start');
            try {
                await fetchConversations();
                
                // Only create a new temporary thread if no threads exist
                if (threadOrder.length === 0) {
                    handleNewThread(defaultModel, false);
                }
            } catch (error) {
                console.error('useThreads: Initialization error:', error);
            }
        };

        initializeThreads();
    }, [fetchConversations, handleNewThread, defaultModel, threadOrder.length]);

    useEffect(() => {
        if (!currentThreadId || !threads.size) return;

        const thread = threads.get(currentThreadId);
          if (!thread) return;

          // Only load content if:
          // 1. It's not a temporary thread
          // 2. It's not already loaded
          // 3. It's not currently loading
          if (!thread.isTemporary && 
              !loadedThreadsRef.current.has(currentThreadId) && 
              !loadingThreadsRef.current.has(currentThreadId)) {
              loadThreadContent(currentThreadId);
          }
    }, [currentThreadId, threads, loadThreadContent]);
  
    useEffect(() => {
        console.log('Thread Loading State:', {
            loadedThreads: Array.from(loadedThreadsRef.current),
            loadingThreads: Array.from(loadingThreadsRef.current),
            currentId: currentThreadId,
            threadsSize: threads.size
        });
    }, [currentThreadId, threads.size]);

    useEffect(() => {
        console.log('useThreads: State updated:', {
            threadsSize: threads.size,
            orderLength: threadOrder.length,
            currentId: currentThreadId,
            currentIndex: currentThreadIndex
        });
    }, [threads.size, threadOrder.length, currentThreadId, currentThreadIndex]);


  return {
    threads,
    setThreads,
    threadOrder,
    setThreadOrder,
    currentThreadId,
    setCurrentThreadId,
    currentThreadIndex,
    isLoading,
    loadingStates,
    error,
    hasMore,
    handleThreadChange,
    handleDeleteThread,
    handleUpdateThreadName,
    loadMoreThreads,
    handleNewThread,
      fetchConversations
  };
};

export default useThreads;
