// src/components/Chat/MessageInput.js

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Paperclip, Send, Square, Wand2 } from 'lucide-react';
import PastesPreview from '../Common/PastesPreview';
import { modelsWithImage } from '../../hooks/usePastes';
import { sanitizeFileName } from '../../utils/utils';
import ExperimentalTooltip from '../Common/ExperimentalTooltip';
import useSettings from '../../hooks/useSettings';

/**
 * Helper function to extract image data from clipboard items
 * @param {DataTransferItemList} clipboardItems - Items from clipboard
 * @returns {Promise<File|null>} - Returns image file or null
 */
const getImageFromClipboard = async (clipboardItems) => {
  for (const item of clipboardItems) {
    if (item.type.startsWith('image/')) {
      const blob = await item.getAsFile();
      return blob;
    }
  }
  return null;
};

/**
 * MessageInput component for handling user input in a chat interface.
 * Allows text input, file uploads, and displays paste previews.
 */
const MessageInput = ({
  onSendMessage,
  onStopGeneration,
  isGenerating,
  pastes,
  onDeletePaste,
  onFileUpload,
  inputMessage,
  setInputMessage,
  onAddPaste,
  currentlySelectedModel,
  experimentalFeatures,
  onToggleExperimental
}) => {
  const textareaRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { isModelWithExperimental } = useSettings({ onLogout: () => {}, defaultModel: currentlySelectedModel });
  const isExperimentalSupported = isModelWithExperimental(currentlySelectedModel);

  /**
   * Adjusts the height of the textarea based on its content to provide auto-resizing.
   */
  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  /**
   * Effect to adjust textarea height whenever pastes or inputMessage change.
   */
  useEffect(() => {
    adjustTextareaHeight();
  }, [pastes, adjustTextareaHeight, inputMessage]);

  /**
   * Handles image file processing for both uploads and pastes
   * @param {File} file - The image file to process
   */
  const handleImageFile = useCallback((file) => {
    const modelSupportsImages = modelsWithImage.some(model => 
      model.value === currentlySelectedModel || 
      currentlySelectedModel.includes('claude-3')
    );
  
    if (!modelSupportsImages) {
      alert('The current model does not support image uploads. Please switch to a model with image support.');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      // Generate a safe filename
      const fileName = file.name || 'pasted-image.png';
      const sanitizedFileName = sanitizeFileName(fileName);
      
      // Get the base64 data without the data:image prefix
      const base64Data = e.target.result.split(',')[1];
      
      // Create the image tag format directly - no XML wrapping
      const imageContent = `[!image]{${sanitizedFileName}}|${base64Data}|[/!image]`;
      
      onAddPaste({
        type: 'image',
        fileName: sanitizedFileName,
        content: imageContent,
        mediaType: file.type,
        isImage: true
      });
    };
    reader.readAsDataURL(file);
  }, [currentlySelectedModel, onAddPaste]);

  /**
   * Handles paste events in the textarea
   * @param {ClipboardEvent} e - The paste event
   */
  const handlePaste = useCallback(async (e) => {
    const hasImages = Array.from(e.clipboardData.items).some(item => 
      item.type.startsWith('image/')
    );
    
    if (hasImages) {
      e.preventDefault();
      
      if (e.clipboardData.files.length > 0) {
        const file = e.clipboardData.files[0];
        if (file.type.startsWith('image/')) {
          handleImageFile(file);
        }
        return;
      }
  
      const image = await getImageFromClipboard(e.clipboardData.items);
      if (image) {
        handleImageFile(image);
      }
    }
  }, [handleImageFile]);

  /**
   * Handles changes in the input textarea, managing paste accumulation and input state.
   */
  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value.length > 2000) {
        const newPasteIndex = pastes.length + 1;
        const newPaste = {
          content: `<paste-${newPasteIndex}>${value}</paste-${newPasteIndex}>`,
          fileName: `Paste ${newPasteIndex}`,
        };
        console.log(`Adding new paste: Paste ${newPasteIndex}`);
        onAddPaste(newPaste);
        setInputMessage('');
      } else {
        setInputMessage(value);
      }
      adjustTextareaHeight();
    },
    [pastes.length, adjustTextareaHeight, onAddPaste, setInputMessage]
  );

  /**
   * Sends the user's message to the backend API and handles the response.
   */
  const handleSendMessage = useCallback(() => {
    const message = inputMessage.trim();
    if (message || pastes.length > 0) {
      onSendMessage(message);
      setInputMessage('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    }
  }, [inputMessage, pastes.length, onSendMessage, setInputMessage]);

  /**
   * Handles the keydown event in the textarea to send message on Enter key.
   */
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  /**
   * Handles file upload with model-specific image support
   */
  const handleFileUploadWrapper = useCallback((event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const files = Array.from(event.target.files);
    
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    const modelSupportsImages = modelsWithImage.some(model => 
      model.value === currentlySelectedModel || 
      currentlySelectedModel.includes('claude-3')
    );

    if (imageFiles.length > 0 && !modelSupportsImages) {
      alert('The current model does not support image uploads. Please switch to a model with image support.');
      event.target.value = null;
      return;
    }

    files.forEach(file => {
      if (file.type.startsWith('image/')) {
        handleImageFile(file);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          const sanitizedFileName = sanitizeFileName(file.name);
          const fileTag = `<${sanitizedFileName}>${content}</${sanitizedFileName}>\n`;
          onAddPaste({ 
            type: 'text',
            fileName: sanitizedFileName,
            content: fileTag
          });
        };
        reader.readAsText(file);
      }
    });
    
    event.target.value = null;
  }, [currentlySelectedModel, onAddPaste, handleImageFile]);

  return (
    <div className="p-4 md:p-2 bg-[#2a2a2a] rounded-lg">
      {pastes.length > 0 && (
        <PastesPreview pastes={pastes} onDeletePaste={onDeletePaste} />
      )}
      <div className="flex items-start">
        <textarea
          ref={textareaRef}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder="Type a message..."
          className="flex-grow bg-transparent p-2 outline-none resize-none min-h-[40px] max-h-[200px] overflow-y-auto"
          rows="1"
        />
        <div className="flex-shrink-0 flex items-center sticky top-0 p-2">
          {/* Experimental Features Toggle */}
          <div className="relative">
            <button
              onClick={onToggleExperimental}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={`p-2 transition-colors ${
                !isExperimentalSupported
                  ? 'text-gray-600' // Dimmed when not supported
                  : experimentalFeatures 
                    ? 'text-purple-400 hover:text-purple-300' 
                    : 'text-gray-400 hover:text-gray-300'
              }`}
              aria-label="Toggle Experimental Features"
            >
              <Wand2 
                size={20}
                className={`transform transition-transform ${
                  experimentalFeatures && isExperimentalSupported ? 'rotate-12 scale-110' : ''
                }`}
              />
            </button>
            {showTooltip && (
              <ExperimentalTooltip 
                isSupported={isExperimentalSupported}
                currentModel={currentlySelectedModel}
              />
            )}
          </div>

          {/* Voice Input - Commented out */}
          {/* <button
            className="p-2 text-gray-400 hover:text-gray-300 transition-colors"
            aria-label="Voice Input"
          >
            <Mic size={20} />
          </button> */}

          {/* File Upload */}
          <button
            onClick={() => document.querySelector('input[type="file"]').click()}
            className="p-2 text-gray-400 hover:text-gray-300 transition-colors"
            aria-label="Attach File"
          >
            <Paperclip size={20} />
          </button>

          {/* Hidden File Input */}
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileUploadWrapper}
            multiple
          />

          {/* Send/Stop Button */}
          {isGenerating ? (
            <button
              onClick={onStopGeneration}
              className="bg-red-500 text-white p-1.5 rounded hover:bg-red-600 transition-colors ml-2"
              aria-label="Stop Generation"
            >
              <Square size={20} />
            </button>
          ) : (
            <button
              onClick={handleSendMessage}
              className="bg-orange-500 text-white p-1.5 rounded hover:bg-orange-600 transition-colors ml-2"
              aria-label="Send Message"
            >
              <Send size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
