// PromoCodeModal.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import config from '../../config';
const PromoCodeModal = ({ isOpen, onClose, onSubmit }) => {
  const [promoCode, setPromoCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!promoCode.trim()) {
      setError('Please enter a promo code.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${config.apiUrl}/redeem_promo`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ promo_code: promoCode }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Promo code redeemed successfully!');
        onSubmit(data);
        setPromoCode('');
      } else {
        setError(data.error || 'Failed to redeem promo code.');
      }
    } catch (err) {
      console.error('Error redeeming promo code:', err);
      setError('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#2c2c2c] text-white rounded-lg p-6 w-80 relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-red-500 hover:text-red-400"
          aria-label="Close"
        >
          <X size={20} />
        </button>
        <h2 className="text-xl font-semibold mb-4">Redeem Promo Code</h2>
        <form onSubmit={handleSubmit} className="flex flex-col">
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter promo code"
            className="p-2 mb-4 rounded bg-gray-700 text-white focus:outline-none"
          />
          {error && <p className="text-red-500 mb-2">{error}</p>}
          {success && <p className="text-green-500 mb-2">{success}</p>}
          <button
            type="submit"
            className="p-2 bg-orange-500 rounded hover:bg-orange-600 transition-colors disabled:opacity-50"
            disabled={loading}
          >
            {loading ? 'Redeeming...' : 'Redeem'}
          </button>
        </form>
      </div>
    </div>
  );
};

PromoCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PromoCodeModal;