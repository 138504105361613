// CollapsibleTile.js
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';

/**
 * CollapsibleTile component that displays a file, paste, or code block as a tile.
 * The file/paste name or "Code" is bolded, with "Click to open attachment" underneath.
 * Clicking the tile toggles the display of the full content.
 * Inline code is rendered with minimal styling, extremely tight to the text.
 *
 * @param {Object} props - Component props
 * @param {string} props.name - Name of the file or paste (optional for code blocks)
 * @param {string} props.content - Full content to display upon expansion
 * @param {Function} props.onToggle - Callback when the tile is expanded or collapsed
 * @param {boolean} props.isCodeBlock - Flag to indicate if the content is a code block
 * @param {string} props.language - Language of the code block (optional)
 * @param {boolean} props.isPreformatted - Flag to indicate if the content should be displayed as-is
 */
const CollapsibleTile = ({ name, content, onToggle, isCodeBlock, language, isPreformatted }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (onToggle) {
      onToggle(isExpanded);
    }
  }, [isExpanded, onToggle]);

  const handleShowMore = useCallback((e) => {
    e.stopPropagation();
    setShowFullContent(true);
  }, []);

  const handleShowLess = useCallback((e) => {
    e.stopPropagation();
    setShowFullContent(false);
  }, []);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(content).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    });
  }, [content]);

  const getLineCount = (text) => {
    return text.split('\n').length;
  };

  const lineCount = getLineCount(content);
  const truncatedContent = content.slice(0, 1000);
  const isLongContent = content.length > 1000;

  const CustomInlineCode = ({ children }) => (
    <code style={{
      fontFamily: 'monospace',
      fontSize: '0.9em',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      padding: '0 1px',
      borderRadius: '2px',
    }}>
      {children}
    </code>
  );

  const renderContent = () => {
    if (isPreformatted) {
      return (
        <pre className="whitespace-pre-wrap break-words">
          {showFullContent || !isLongContent ? content : truncatedContent}
        </pre>
      );
    } else if (isCodeBlock) {
      return (
        <SyntaxHighlighter
          language={language || 'text'}
          style={vscDarkPlus}
          customStyle={{ maxHeight: 'none', maxWidth: '100%', paddingTop: '2rem' }}
          wrapLines={true}
          wrapLongLines={true}
        >
          {showFullContent || !isLongContent ? content : truncatedContent}
        </SyntaxHighlighter>
      );
    } else {
      return (
        <ReactMarkdown
          components={{
            code({ node, inline, className, children, ...props }) {
              return inline ? (
                <CustomInlineCode>{children}</CustomInlineCode>
              ) : (
                <SyntaxHighlighter
                  language={language || 'text'}
                  style={vscDarkPlus}
                  PreTag="div"
                  customStyle={{ maxWidth: '100%', paddingTop: '2rem' }}
                  wrapLines={true}
                  wrapLongLines={true}
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              );
            },
          }}
        >
          {showFullContent || !isLongContent ? content : truncatedContent}
        </ReactMarkdown>
      );
    }
  };

  return (
    <div className="mb-2 mr-2 flex flex-col max-w-full">
      <button
        onClick={handleToggle}
        className={`bg-[#2c2c2c] text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-200 ${
          isExpanded ? 'bg-[#2c2c2c]' : ''
        }`}
      >
        <div className="font-bold bg-[#2c2c2c]">{isCodeBlock ? 'Code' : name}</div>
        <div className="text-sm bg-[#2c2c2c]">Click to open</div>
      </button>
      {isExpanded && (
        <div className="mt-2 p-4 bg-[#1E1E1E] rounded-lg text-left max-h-[70vh] max-w-full overflow-auto relative">
          <button
            onClick={handleCopy}
            className="absolute top-2 right-2 bg-blue-500 text-white px-2 py-1 rounded text-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors duration-200"
          >
            {isCopied ? 'Copied' : 'Copy'}
          </button>
          <div className="absolute top-2 left-2 bg-[#2D2D2D] text-white px-2 py-1 rounded text-sm">
            {lineCount} lines
          </div>
          <div className="max-w-full">
            {renderContent()}
          </div>
          {isLongContent && (
            <div className="mt-2">
              {!showFullContent ? (
                <button
                  onClick={handleShowMore}
                  className="text-blue-400 hover:text-blue-300"
                >
                  Show More
                </button>
              ) : (
                <button
                  onClick={handleShowLess}
                  className="text-blue-400 hover:text-blue-300"
                >
                  Show Less
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CollapsibleTile.propTypes = {
  name: PropTypes.string,
  content: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  isCodeBlock: PropTypes.bool,
  language: PropTypes.string,
  isPreformatted: PropTypes.bool,
};

CollapsibleTile.defaultProps = {
  isCodeBlock: false,
  isPreformatted: false,
};

export default CollapsibleTile;
