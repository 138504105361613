import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleTile from './Common/CollapsibleTile';
import ReactMarkdown from 'react-markdown';

/**
 * UserMessage Component
 * Renders user messages including text, images, and other content types
 * Includes enhanced markdown processing for proper newline and formatting handling
 */
const UserMessage = React.memo(({ content, onTileToggle }) => {
    /**
     * Constructs proper image URL from image data
     * @param {string} imageData - The base64 image data
     * @param {string} mimeType - Optional MIME type, defaults to jpeg
     * @returns {string|null} - Complete data URL for the image
     */
    const createImageUrl = (imageData, mimeType = 'image/jpeg') => {
        if (!imageData) return null;
        
        // Handle already formatted data URLs
        if (imageData.startsWith('data:')) {
            return imageData;
        }

        // Create new data URL
        try {
            return `data:${mimeType};base64,${imageData.trim()}`;
        } catch (error) {
            console.error('Failed to create image URL');
            return null;
        }
    };

    /**
     * Renders individual image elements
     * @param {string} imageData - The image data to render
     * @param {string} filename - Name of the image file
     * @param {string} key - React key for the element
     */
    const renderImage = (imageData, filename, key) => {
        const imageUrl = createImageUrl(imageData);
        if (!imageUrl) return null;

        return (
            <div key={key} className="my-2">
                <img
                    src={imageUrl}
                    alt={filename || 'Uploaded image'}
                    className="max-w-full rounded-lg"
                    onError={(e) => {
                        console.error('Image failed to load:', filename);
                        e.target.style.display = 'none';
                    }}
                />
            </div>
        );
    };

    /**
     * Preprocesses text content for markdown rendering
     * Handles special cases and formatting
     * @param {string} text - The text content to process
     * @returns {string} - Processed text ready for markdown rendering
     */
    const preprocessMarkdownText = (text) => {
        return text
            // Ensure consistent newlines
            .replace(/\r\n/g, '\n')
            // Handle consecutive newlines for paragraph breaks
            .replace(/\n{3,}/g, '\n\n')
            // Add two spaces before single newlines for line breaks
            .replace(/([^\n])\n([^\n])/g, '$1  \n$2')
            // Properly escape special markdown characters that should be literal
            .replace(/([\\`*_{}[\]()#+-.!])/g, '\\$1')
            // Remove any null characters
            .replace(/\0/g, '')
            // Trim extra whitespace while preserving intended formatting
            .trim();
    };

    /**
     * Renders markdown content with custom styling and enhanced formatting
     * @param {string} text - The text content to render as markdown
     * @param {string} key - React key for the element
     */
    const renderMarkdown = (text, key) => (
        <ReactMarkdown
            key={key}
            breaks={true}
            components={{
                a: ({ node, children, ...props }) => (
                    <a
                        {...props}
                        className="text-orange-500 underline hover:text-orange-600"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {children}
                    </a>
                ),
                p: ({ children }) => (
                    <p className="mb-2">{children}</p>
                ),
                code: ({ node, inline, children, ...props }) => (
                    inline ? 
                        <code className="bg-gray-800 px-1 rounded" {...props}>{children}</code> :
                        <pre className="bg-gray-800 p-2 rounded overflow-x-auto">
                            <code {...props}>{children}</code>
                        </pre>
                ),
                // Add spacing between list items
                li: ({ children }) => (
                    <li className="mb-1">{children}</li>
                ),
                // Add spacing for headers
                h1: ({ children }) => <h1 className="text-2xl font-bold mb-4">{children}</h1>,
                h2: ({ children }) => <h2 className="text-xl font-bold mb-3">{children}</h2>,
                h3: ({ children }) => <h3 className="text-lg font-bold mb-2">{children}</h3>,
                // Add styling for blockquotes
                blockquote: ({ children }) => (
                    <blockquote className="border-l-4 border-gray-500 pl-4 my-2 italic">
                        {children}
                    </blockquote>
                ),
            }}
        >
            {preprocessMarkdownText(text)}
        </ReactMarkdown>
    );

    /**
     * Main function to render all message parts
     * Handles both array-based and string-based content formats
     */
    const renderMessageParts = () => {
        // Handle array-based content (new format)
        if (Array.isArray(content)) {
            return content.map((part, index) => {
                if (part.type === 'image') {
                    return renderImage(part.content, part.fileName, `image-${index}`);
                }
                if (part.type === 'text') {
                    return renderMarkdown(part.text, `text-${index}`);
                }
                return null;
            });
        }

        // Handle string-based content
        if (typeof content === 'string') {
            const parts = [];
            let currentIndex = 0;
            let lastIndex = 0;

            // New image format
            const imageRegex = /\[!image\]{([^}]+)}\|(.*?)\|\[\/!image\]/gs;
            let match;

            while ((match = imageRegex.exec(content)) !== null) {
                const [fullMatch, filename, imageData] = match;
                const { index } = match;

                // Add text before the image
                if (index > lastIndex) {
                    const textContent = content.substring(lastIndex, index);
                    if (textContent.trim()) {
                        parts.push(renderMarkdown(textContent, `text-${currentIndex}`));
                        currentIndex++;
                    }
                }

                // Add the image
                parts.push(renderImage(imageData, filename, `image-${currentIndex}`));
                currentIndex++;
                lastIndex = index + fullMatch.length;
            }

            // Handle regular pastes with the original XML-style tags
            const remainingContent = content.substring(lastIndex);
            if (remainingContent.trim()) {
                const pasteRegex = /<([^>]+)>([\s\S]*?)<\/\1>/g;
                let pasteMatch;
                let pasteLastIndex = 0;

                while ((pasteMatch = pasteRegex.exec(remainingContent)) !== null) {
                    const [fullMatch, filename, pasteContent] = pasteMatch;
                    const { index } = pasteMatch;

                    // Add text before the paste
                    if (index > pasteLastIndex) {
                        const textContent = remainingContent.substring(pasteLastIndex, index);
                        if (textContent.trim()) {
                            parts.push(renderMarkdown(textContent, `text-${currentIndex}`));
                            currentIndex++;
                        }
                    }

                    // Add the paste content
                    parts.push(
                        <CollapsibleTile
                            key={`tile-${currentIndex}`}
                            name={filename}
                            content={pasteContent}
                            onToggle={onTileToggle}
                            isPreformatted={true}
                        />
                    );
                    currentIndex++;
                    pasteLastIndex = index + fullMatch.length;
                }

                // Add any remaining text
                const finalText = remainingContent.substring(pasteLastIndex);
                if (finalText.trim()) {
                    parts.push(renderMarkdown(finalText, `text-${currentIndex}`));
                }
            }

            return parts;
        }

        return <div>Unable to display message content</div>;
    };

    return (
        <div className="flex flex-col items-end">
            <div className="bg-zinc-700 p-2 rounded-lg text-left max-w-[90%] overflow-x-auto inline-block">
                {renderMessageParts()}
            </div>
        </div>
    );
});

UserMessage.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            text: PropTypes.string,
            fileName: PropTypes.string
        }))
    ]).isRequired,
    onTileToggle: PropTypes.func,
};

export default UserMessage;
