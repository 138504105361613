// src/components/Projects/ProjectsPage.js
import React from 'react';
import ProjectsHeader from './ProjectsHeader';
import ProjectCard from './ProjectCard';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

/**
 * Main projects page component
 * Displays project list and provides navigation to create new projects
 */
const ProjectsPage = () => {
  const navigate = useNavigate();

  // Temporary mock projects
  const mockProjects = [
    {
      id: '1',
      name: 'AI Research Assistant',
      description: 'Project for researching AI topics and summarizing papers',
      model: 'claude-3-5-sonnet-20241022'
    },
    {
      id: '2',
      name: 'Code Review Helper',
      description: 'AI-powered code review and suggestions',
      model: 'gpt-4-turbo'
    },
    {
      id: '3',
      name: 'Content Writer',
      description: 'Blog post and article generation assistant',
      model: 'claude-3-opus-20240229'
    }
  ];

  return (
    <div className="h-screen flex flex-col">
      <ProjectsHeader />
      <div className="flex-1 bg-[#1a1a1a] p-6">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-white">Projects</h1>
            <button
              onClick={() => navigate('/projects/new')}
              className="flex items-center px-4 py-2 bg-orange-500 rounded hover:bg-orange-600 transition-colors"
            >
              <Plus size={20} className="mr-2" />
              Create Project
            </button>
          </div>
          
          <div className="grid gap-4">
            {mockProjects.map(project => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
