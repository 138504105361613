// src/components/Pricing/PricingPage.js
import React, { useState } from 'react';
import PricingTierCard from './PricingTierCard';
import BillingToggle from './BillingToggle';
import PageHeader from './PageHeader';
import { pricingTiers } from './PricingData';

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState('yearly');

  const topTiers = pricingTiers.filter(tier => tier.tier === 'top');
  const bottomTiers = pricingTiers.filter(tier => tier.tier === 'bottom');

  return (
    <div className="min-h-screen flex flex-col bg-[#1a1a1a] text-white">
      <PageHeader />
      
      <div className="flex-1 py-12 px-4 relative overflow-hidden">
        <div className="max-w-7xl mx-auto">
          {/* Top Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">
              Choose Your Plan
            </h1>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Access powerful AI models and features with our flexible pricing plans
            </p>
          </div>

          <BillingToggle 
            billingCycle={billingCycle} 
            onChange={setBillingCycle} 
          />

          {/* Container for both rows with relative positioning */}
          <div className="relative">
            {/* Top Tier Cards */}
            <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mt-12">
              {topTiers.map((tier) => (
                <PricingTierCard
                  key={tier.name}
                  tier={tier}
                  billingCycle={billingCycle}
                />
              ))}
            </div>

            {/* Bottom Tier Cards - Positioned lower */}
            <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mt-[10vh] relative">
              {/* Gradient overlay */}
              <div 
                className="absolute -top-20 left-0 right-0 h-40 
                bg-gradient-to-b from-[#1a1a1a] to-transparent z-10"
              />
              {bottomTiers.map((tier) => (
                <PricingTierCard
                  key={tier.name}
                  tier={tier}
                  billingCycle={billingCycle}
                />
              ))}
            </div>
          </div>

          {/* Footer text */}
          <div className="mt-16 text-center">
            <p className="text-gray-400">
              All plans include basic features like chat history. Usage varies based with model selection.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
