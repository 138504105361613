// Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  DollarSign,
  CreditCard,
  Plus,
  X,
  Gift,
  Search,
  Info,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CreditUsageWheel from './CreditUsageWheel';
import PromoCodeModal from './Modals/PromoCodeModal';
import ThreadSidebar from './Sidebar/ThreadSidebar'; // Import the new sidebar
import config from '../config';
import * as api from '../services/api';

/**
 * PRICING data for various models.
 * Each model includes input and output pricing per 1M tokens,
 * optional image_token_ratio, and a category for organization.
 */
const PRICING = {
  "gemini-1.5-flash-latest": {
    input: 0.105,  // 0.075 * 1.4
    output: 0.42,  // 0.30 * 1.4
    category: "Google",
  },
  "gemini-2.0-flash-exp": {
    input: 0.105,  // 0.075 * 1.4
    output: 0.42,  // 0.30 * 1.4
    category: "Google",
  },
  "gemini-1.5-flash-8b": {
    input: 0.0525,  // 0.0375 * 1.4
    output: 0.21,   // 0.15 * 1.4
    category: "Google",
  },
  "gemini-1.5-pro-latest": {
    input: 1.75,   // 1.25 * 1.4
    output: 7.00,  // 5.00 * 1.4
    category: "Google",
  },
  "gpt-3.5-turbo": {
    input: 0.70,   // 0.50 * 1.4
    output: 2.10,  // 1.50 * 1.4
    category: "OpenAI",
  },
  "gpt-4-turbo": {
    input: 14.00,  // 10.00 * 1.4
    output: 42.00, // 30.00 * 1.4
    category: "OpenAI",
  },
  "gpt-4o-2024-11-20": {
    input: 3.50,   // 2.50 * 1.4
    output: 14.00, // 10.00 * 1.4
    category: "OpenAI",
  },
  "chatgpt-4o-latest": {
    input: 7.00,   // 5.00 * 1.4
    output: 21.00, // 15.00 * 1.4
    category: "OpenAI",
  },
  "claude-3-5-haiku-20241022": {
    input: 1.40,   // 1.00 * 1.4
    output: 7.00,  // 5.00 * 1.4
    category: "Anthropic",
  },
  "claude-3-5-sonnet-20241022": {
    input: 4.20,   // 3.00 * 1.4
    output: 21.00, // 15.00 * 1.4
    image_token_ratio: 750,
    category: "Anthropic",
  },
  "claude-3-opus-20240229": {
    input: 21.00,   // 15.00 * 1.4
    output: 105.00, // 75.00 * 1.4
    image_token_ratio: 750,
    category: "Anthropic",
  },
  "claude-3-haiku-20240307": {
    input: 0.35,   // 0.25 * 1.4
    output: 1.75,  // 1.25 * 1.4
    image_token_ratio: 750,
    category: "Anthropic",
  },
  "reka-spark": {
    input: 0.07,    // 0.05 * 1.4
    output: 0.07,   // 0.05 * 1.4
    image: 0.00035, // 0.00025 * 1.4
    video: 0.00035, // 0.00025 * 1.4
    audio: 0.0035,  // 0.0025 * 1.4
    category: "Reka",
  },
  "reka-edge": {
    input: 0.14,    // 0.1 * 1.4
    output: 0.14,   // 0.1 * 1.4
    image: 0.0007,  // 0.0005 * 1.4
    video: 0.0007,  // 0.0005 * 1.4
    audio: 0.007,   // 0.005 * 1.4
    category: "Reka",
  },
  "reka-flash": {
    input: 0.28,    // 0.2 * 1.4
    output: 1.12,   // 0.8 * 1.4
    image: 0.0014,  // 0.001 * 1.4
    video: 0.0014,  // 0.001 * 1.4
    audio: 0.014,   // 0.01 * 1.4
    category: "Reka",
  },
  "reka-core": {
    input: 2.80,    // 2.0 * 1.4
    output: 2.80,   // 2.0 * 1.4
    image: 0.0028,  // 0.002 * 1.4
    video: 0.0028,  // 0.002 * 1.4
    audio: 0.028,   // 0.02 * 1.4
    category: "Reka",
  },
  "reka-core-20240904": {
    input: 2.80,    // 2.0 * 1.4
    output: 2.80,   // 2.0 * 1.4
    image: 0.0028,  // 0.002 * 1.4
    video: 0.0028,  // 0.002 * 1.4
    audio: 0.028,   // 0.02 * 1.4
    category: "Reka",
  },
  "reka-flash-20240904": {
    input: 0.28,    // 0.2 * 1.4
    output: 1.12,   // 0.8 * 1.4
    image: 0.0014,  // 0.001 * 1.4
    video: 0.0014,  // 0.001 * 1.4
    audio: 0.014,   // 0.01 * 1.4
    category: "Reka",
  },
  "reka-edge-20240208": {
    input: 0.14,    // 0.1 * 1.4
    output: 0.14,   // 0.1 * 1.4
    image: 0.0007,  // 0.0005 * 1.4
    video: 0.0007,  // 0.0005 * 1.4
    audio: 0.007,   // 0.005 * 1.4
    category: "Reka",
  },
  "deepseek-chat": {
    input: 0.196,   // 0.14 * 1.4
    output: 0.392,  // 0.28 * 1.4
    category: "DeepSeek",
  },
  "o1-mini": {
    input: 4.20,    // 3.00 * 1.4
    output: 16.80,  // 12.00 * 1.4
    category: "OpenAI",
  },
  "o1-preview": {
    input: 21.00,   // 15.00 * 1.4
    output: 84.00,  // 60.00 * 1.4
    category: "OpenAI",
  },
  "Meta-Llama-3.1-70b-Instruct": {
    input: 0.84,    // 0.60 * 1.4
    output: 1.68,   // 1.20 * 1.4
    category: "Meta",
  },
  "Meta-Llama-3.1-8b-Instruct": {
    input: 0.14,    // 0.10 * 1.4
    output: 0.28,   // 0.20 * 1.4
    category: "Meta",
  },
  "Meta-Llama-3.1-405B-Instruct": {
    input: 7.00,    // 5.00 * 1.4
    output: 14.00,  // 10.00 * 1.4
    category: "Meta",
  },
  "gpt-4o-mini": {
    input: 0.21,    // 0.15 * 1.4
    output: 0.84,   // 0.60 * 1.4
    category: "OpenAI",
  },
  "grok-beta": {
    input: 7.00,    // 5.00 * 1.4
    output: 21.00,  // 15.00 * 1.4
    category: "X.AI",
  },
};


/**
 * PriceCard
 * Displays pricing information for a single model.
 * Includes input/output prices and optional image token ratio.
 */
const PriceCard = ({ model, pricing }) => {
  const [showDetails, setShowDetails] = useState(false);

  // Function to format number as credits
  const formatCredits = (number) => {
    if (number < 0.001) {
      return number.toExponential(3);
    }
    return number.toFixed(3);
  };

  // Function to calculate tokens per credit
  const calculateTokensPerCredit = (price) => {
    return Math.round(1000000 / price); // 1M tokens divided by price in credits
  };

  return (
    <div className="bg-[#2a2a2a] rounded-lg p-4 hover:bg-[#333] transition-colors duration-200">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-medium text-gray-200">{model}</h3>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className="text-orange-500 hover:text-orange-400"
          aria-label="Toggle Details"
        >
          <Info size={16} />
        </button>
      </div>
      <div className="mt-2 space-y-1">
        <div className="text-xs text-gray-400">
          Input: {formatCredits(pricing.input)} credits/1M tokens
          <span className="text-gray-500 ml-1">
            ({calculateTokensPerCredit(pricing.input)} tokens/credit)
          </span>
        </div>
        <div className="text-xs text-gray-400">
          Output: {formatCredits(pricing.output)} credits/1M tokens
          <span className="text-gray-500 ml-1">
            ({calculateTokensPerCredit(pricing.output)} tokens/credit)
          </span>
        </div>
        {pricing.image_token_ratio && (
          <div className="text-xs text-gray-400">
            Image Ratio: 1:{pricing.image_token_ratio}
          </div>
        )}
      </div>
      {showDetails && (
        <div className="mt-3 pt-3 border-t border-[#444]">
          <div className="text-xs text-gray-300">
            Average Cost per 1M tokens (66% input, 34% output):
            <div className="font-mono mt-1">
              {formatCredits((pricing.input * 0.66) + (pricing.output * 0.34))} credits
            </div>
          </div>
          {pricing.image && (
            <div className="text-xs text-gray-300 mt-2">
              Image Processing: {formatCredits(pricing.image)} credits per unit
            </div>
          )}
          {pricing.video && (
            <div className="text-xs text-gray-300 mt-1">
              Video Processing: {formatCredits(pricing.video)} credits per unit
            </div>
          )}
          {pricing.audio && (
            <div className="text-xs text-gray-300 mt-1">
              Audio Processing: {formatCredits(pricing.audio)} credits per unit
            </div>
          )}
        </div>
      )}
    </div>
  );
};
/**
 * Header component for the dashboard
 * Matches styling with ProjectsHeader and includes logout functionality with confirmation
 */
const DashboardHeader = ({ onLogout }) => {
  const navigate = useNavigate();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  // Handler to initiate logout confirmation
  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  // Handler to confirm logout
  const confirmLogout = () => {
    onLogout();
    setShowLogoutConfirm(false);
  };

  // Handler to cancel logout
  const cancelLogout = () => {
    setShowLogoutConfirm(false);
  };

  return (
    <>
      <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30 border-b border-[#3a3a3a]">
        <div className="flex items-center">
          <div 
            className="flex items-center cursor-pointer" 
            onClick={() => navigate('/')}
          >
            <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
            <div className="text-lg font-bold text-white">henosis</div>
          </div>
        </div>
        <button
          onClick={handleLogoutClick}
          className="px-3 py-1.5 bg-red-500 rounded hover:bg-red-600 transition-colors text-sm"
          aria-label="Logout"
        >
          Logout
        </button>
      </header>

      {/* Logout Confirmation Modal */}
      {showLogoutConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#2a2a2a] p-6 rounded-lg shadow-xl max-w-sm w-full">
            <h2 className="text-lg font-bold mb-4 text-white">Confirm Logout</h2>
            <p className="mb-6 text-gray-300">Are you sure you want to log out?</p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={cancelLogout}
                className="px-4 py-1.5 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmLogout}
                className="px-4 py-1.5 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
/**
 * Dashboard
 * Main component displaying usage statistics, pricing table,
 * and integrating the new sidebar theme.
 */
const Dashboard = ({ onLogout }) => {
  const [usageData, setUsageData] = useState({});
  const [dailyUsageData, setDailyUsageData] = useState([]);
  const [isModelView, setIsModelView] = useState(true);  // Changed to true to make bar graph default
  const [totalCost, setTotalCost] = useState(0);
  const [loadedCredits, setLoadedCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('daily');
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [threads, setThreads] = useState([]);
  const [modelIds, setModelIds] = useState([]);
  const [colors, setColors] = useState({});
  const navigate = useNavigate();
  const [userTier, setUserTier] = useState('');
  const [isTestAccount, setIsTestAccount] = useState(false);
  /**
   * Fetch data on component mount
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const dashboardData = await api.getDashboardData();
  
        if (dashboardData) {
          setTotalCost(dashboardData.credits_used);
          setLoadedCredits(dashboardData.tier_credits);
          setUserTier(dashboardData.tier_name);
          setIsTestAccount(dashboardData.is_test_account);
          processUsageData(dashboardData);
        }
  
        const conversationsData = await api.getConversations();
        setThreads(conversationsData || []);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };
  
    fetchData();
  }, []);
  /**
   * Fetches usage data from the server
   */
  const fetchUsageData = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/model_calls`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      const responseText = await response.text();
      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
          throw new Error('Unauthorized access. Please log in again.');
        }
        try {
          const errorData = JSON.parse(responseText);
          throw new Error(errorData.error || 'Failed to fetch usage data');
        } catch (jsonError) {
          throw new Error(
            `Server error: ${response.status} ${response.statusText}`
          );
        }
      }
      const data = JSON.parse(responseText);
      processUsageData(data.model_calls);
      setTotalCost(
        data.model_calls.reduce((acc, curr) => acc + curr.total_cost, 0)
      );
    } catch (err) {
      setError(err.message);
    }
  };

  /**
   * Fetches user credits from the server
   */
  const fetchUserCredits = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user_credits`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      const responseText = await response.text();
      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
          throw new Error('Unauthorized access. Please log in again.');
        }
        try {
          const errorData = JSON.parse(responseText);
          throw new Error(errorData.error || 'Failed to fetch user credits');
        } catch (jsonError) {
          throw new Error(
            `Server error: ${response.status} ${response.statusText}`
          );
        }
      }
      const data = JSON.parse(responseText);
      setLoadedCredits(data.loaded_credits);
    } catch (err) {
      setError(err.message);
    }
  };

  /**
   * Fetches conversations (threads) from the server
   */
  const getConversations = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/get_conversations`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        if (response.status === 401) {
          onLogout();
        }
        throw new Error('Failed to get conversations');
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        const sortedThreads = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setThreads(sortedThreads);
      } else {
        setThreads([]);
      }
    } catch (error) {
      if (error instanceof Error && error.message.includes('401')) {
        onLogout();
      }
      setThreads([]);
    }
  };

  /**
   * Processes the usage data from model calls
   * Aggregates data for yearly, monthly, and daily views
   */
  const processUsageData = (data) => {
    if (!data || !data.daily_data) {
      return;
    }
  
    // Set the data directly for bar graph
    setDailyUsageData(data.daily_data);
    
    // Process data for line graph
    const yearlyDataMap = {};
    const monthlyDataMap = {};
    const dailyDataMap = {};
  
    data.daily_data.forEach((entry) => {
      const date = new Date(entry.period);
      const year = date.getFullYear().toString();
      const month = `${year}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      const day = entry.period;
  
      // Calculate total cost for this entry
      const totalCost = Object.entries(entry)
        .filter(([key]) => key !== 'period')
        .reduce((sum, [, cost]) => sum + cost, 0);
  
      // Calculate total tokens (rough estimate based on cost)
      const estimatedTokens = Math.round(totalCost * 1000000); // Very rough estimate
  
      // Yearly aggregation
      if (!yearlyDataMap[year]) {
        yearlyDataMap[year] = {
          period: year,
          cumulative_cost: 0,
          cumulative_tokens: 0,
        };
      }
      yearlyDataMap[year].cumulative_tokens += estimatedTokens;
      yearlyDataMap[year].cumulative_cost += totalCost;
  
      // Monthly aggregation
      if (!monthlyDataMap[month]) {
        monthlyDataMap[month] = {
          period: month,
          cumulative_cost: 0,
          cumulative_tokens: 0,
        };
      }
      monthlyDataMap[month].cumulative_tokens += estimatedTokens;
      monthlyDataMap[month].cumulative_cost += totalCost;
  
      // Daily aggregation
      if (!dailyDataMap[day]) {
        dailyDataMap[day] = {
          period: day,
          cumulative_cost: 0,
          cumulative_tokens: 0,
        };
      }
      dailyDataMap[day].cumulative_tokens += estimatedTokens;
      dailyDataMap[day].cumulative_cost += totalCost;
  
      // Store model-specific costs
      Object.entries(entry).forEach(([modelId, cost]) => {
        if (modelId !== 'period') {
          if (!dailyDataMap[day][modelId]) {
            dailyDataMap[day][modelId] = 0;
          }
          dailyDataMap[day][modelId] += cost;
        }
      });
    });
  
    // Convert maps to sorted arrays
    const yearlyData = Object.values(yearlyDataMap).sort((a, b) =>
      a.period.localeCompare(b.period)
    );
    const monthlyData = Object.values(monthlyDataMap).sort((a, b) =>
      a.period.localeCompare(b.period)
    );
    const dailyData = Object.values(dailyDataMap).sort((a, b) =>
      a.period.localeCompare(b.period)
    );
  
    // Calculate cumulative totals
    const calculateCumulative = (data, key) => {
      let cumulative = 0;
      return data.map((item) => {
        cumulative += item[key];
        return { ...item, [`cumulative_${key}`]: cumulative };
      });
    };
  
    // Apply cumulative calculations
    const cumulativeYearlyData = calculateCumulative(yearlyData, 'cumulative_cost');
    const cumulativeMonthlyData = calculateCumulative(monthlyData, 'cumulative_cost');
    const cumulativeDailyData = calculateCumulative(dailyData, 'cumulative_cost');
  
    const cumulativeYearlyTokens = calculateCumulative(yearlyData, 'cumulative_tokens');
    const cumulativeMonthlyTokens = calculateCumulative(monthlyData, 'cumulative_tokens');
    const cumulativeDailyTokens = calculateCumulative(dailyData, 'cumulative_tokens');
  
    // Merge token data with cost data
    const mergeCumulativeData = (costData, tokenData) => {
      return costData.map((item, index) => ({
        ...item,
        cumulative_tokens: tokenData[index].cumulative_tokens,
      }));
    };
  
    setUsageData({
      yearly: mergeCumulativeData(cumulativeYearlyData, cumulativeYearlyTokens),
      monthly: mergeCumulativeData(cumulativeMonthlyData, cumulativeMonthlyTokens),
      daily: mergeCumulativeData(cumulativeDailyData, cumulativeDailyTokens),
    });
  
    // Get unique model IDs from the data
    const uniqueModelIds = [...new Set(
      data.daily_data.flatMap(entry => 
        Object.keys(entry).filter(key => key !== 'period')
      )
    )];
    setModelIds(uniqueModelIds);
  
    // Generate colors for models
    const generatedColors = {};
    uniqueModelIds.forEach((model, index) => {
      generatedColors[model] = getColor(index);
    });
    setColors(generatedColors);
  };
  

  /**
   * Provides a color from a palette based on the index
   */
  const getColor = (index) => {
    const palette = [
      '#8884d8',
      '#82ca9d',
      '#ffc658',
      '#ff8042',
      '#8dd1e1',
      '#a4de6c',
      '#d0ed57',
      '#a28dd8',
      '#ca82ca',
      '#c65865',
      '#8042ff',
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
    ];
    return palette[index % palette.length];
  };

  /**
   * Toggles between usage view and model view
   */
  const toggleView = () => {
    setIsModelView(!isModelView);
  };

  /**
   * Handles the submission of the promo code
   */
  const handlePromoSubmit = (data) => {
    setLoadedCredits(data.new_balance); // Update loaded credits with new balance
    setTotalCost((prev) => prev); // Optionally update total cost if needed
    setIsPromoModalOpen(false); // Close the modal
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#1a1a1a] text-white">
        <div className="animate-pulse">Loading dashboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#1a1a1a] text-white">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-[#1a1a1a] text-white">
      {/* Sidebar */}
      <ThreadSidebar threads={threads} />
  
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <DashboardHeader onLogout={onLogout} />

        {/* Main Dashboard Content */}
        <main className="flex-1 overflow-auto p-6 space-y-6 scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full">
          {/* Stats Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Credits Used */}
            <div className="bg-[#333] p-6 rounded-lg flex items-center">
              <DollarSign className="w-8 h-8 text-green-400 mr-4" />
              <div>
                <p className="text-gray-400">Credits Used This Month</p>
                <p className="text-2xl font-bold">${totalCost.toFixed(2)}</p>
              </div>
            </div>

            <div className="bg-[#333] p-6 rounded-lg flex items-center justify-between">
              <div className="flex items-center">
                <CreditCard className="w-8 h-8 text-blue-400 mr-4" />
                <div>
                  <p className="text-gray-400">
                    {isTestAccount ? 'Test Account' : userTier}
                  </p>
                  <p className="text-2xl font-bold">
                    ${loadedCredits.toFixed(2)}/month
                  </p>
                </div>
              </div>
              {/* Promo Code Button */}
              <button
                onClick={() => setIsPromoModalOpen(true)}
                className="p-2 bg-orange-500 rounded hover:bg-orange-600 transition-colors"
                aria-label="Redeem Promo Code"
              >
                <Gift size={20} />
              </button>
            </div>
            
            {/* Credit Usage Wheel */}
            <div className="bg-[#333] p-6 rounded-lg flex items-center justify-center">
              <CreditUsageWheel
                totalCost={totalCost}
                loadedCredits={loadedCredits}
              />
            </div>
          </div>
 
          {/* Usage Graph */}
          <div className="bg-[#333] p-6 rounded-lg">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {isModelView ? 'Cost by Model' : 'Usage Over Time'}
              </h2>
              <div className="flex items-center space-x-4">
                {!isModelView && (
                  <select
                    value={timeframe}
                    onChange={(e) => setTimeframe(e.target.value)}
                    className="bg-[#2a2a2a] rounded px-3 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-orange-500"
                  >
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="daily">Daily</option>
                  </select>
                )}
                <button
                  onClick={toggleView}
                  className="bg-orange-500 hover:bg-orange-600 text-white px-3 py-1 rounded transition-colors"
                >
                  {isModelView ? 'View Usage Over Time' : 'View Usage By Model'}
                </button>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              {isModelView ? (
                <BarChart data={dailyUsageData}>
                  <XAxis dataKey="period" stroke="#fff" />
                  <YAxis
                    label={{
                      value: 'Cost ($)',
                      angle: -90,
                      position: 'insideLeft',
                      fill: '#fff',
                    }}
                    stroke="#fff"
                  />
                  <Tooltip />
                  <Legend />
                  {modelIds.map((modelId) => (
                    <Bar
                      key={modelId}
                      dataKey={modelId}
                      stackId="a"
                      fill={colors[modelId]}
                      name={modelId}
                    />
                  ))}
                </BarChart>
              ) : (
                <LineChart data={usageData[timeframe]}>
                  <XAxis dataKey="period" stroke="#fff" />
                  <YAxis
                    yAxisId="left"
                    label={{
                      value: 'Tokens',
                      angle: -90,
                      position: 'insideLeft',
                      fill: '#fff',
                    }}
                    stroke="#fff"
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    label={{
                      value: 'Cost ($)',
                      angle: 90,
                      position: 'insideRight',
                      fill: '#fff',
                    }}
                    stroke="#fff"
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    yAxisId="left"
                    type="linear"
                    dataKey="cumulative_tokens"
                    stroke="#8884d8"
                    name="Tokens"
                  />
                  <Line
                    yAxisId="right"
                    type="linear"
                    dataKey="cumulative_cost"
                    stroke="#82ca9d"
                    name="Cumulative Cost ($)"
                  />
                </LineChart>
              )}
            </ResponsiveContainer>
          </div>
  
          {/* Pricing Table */}
          <div className="bg-[#333] p-6 rounded-lg">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Model Pricing</h2>
              <div className="flex space-x-4">
                <div className="relative">
                  <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search models..."
                    className="pl-10 pr-4 py-2 bg-[#2a2a2a] rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-orange-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <select
                  className="bg-[#2a2a2a] rounded-lg px-4 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-orange-500"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  {['All', 'Google', 'OpenAI', 'Anthropic', 'Reka', 'DeepSeek', 'Meta', "X.AI"].map(
                    (category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {Object.entries(PRICING)
                .filter(
                  ([model, pricing]) =>
                    model.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    (selectedCategory === 'All' ||
                      pricing.category === selectedCategory)
                )
                .map(([model, pricing]) => (
                  <PriceCard key={model} model={model} pricing={pricing} />
                ))}
            </div>
          </div>
        </main>
      </div>
  
      {/* Promo Code Modal */}
      <PromoCodeModal
        isOpen={isPromoModalOpen}
        onClose={() => setIsPromoModalOpen(false)}
        onSubmit={handlePromoSubmit}
      />
    </div>
  );
};

export default Dashboard;
