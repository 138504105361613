import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CollapsibleTile from './Common/CollapsibleTile';

/**
 * AssistantMessage component that renders assistant messages with enhanced markdown support
 * Uses memoization to prevent unnecessary re-renders and improve text selection
 * 
 * @param {Object} props - Component props
 * @param {string} props.content - Message text content
 * @param {Array} props.files - Array of file objects
 * @param {Array} props.pastes - Array of paste objects
 * @param {Function} props.onTileToggle - Callback when a tile is toggled
 */
const AssistantMessage = React.memo(({ content = '', files = [], pastes = [], onTileToggle }) => {
  // Memoize the code block extraction to prevent unnecessary recalculation
  const codeBlocks = useMemo(() => {
    if (!content) {
      return [{ type: 'text', content: '' }];
    }

    const codeBlockRegex = /^```(\w+)?\n([\s\S]*?)^```/gm;
    const blocks = [];
    let lastIndex = 0;
    let match;

    while ((match = codeBlockRegex.exec(content)) !== null) {
      if (lastIndex < match.index) {
        blocks.push({ type: 'text', content: content.slice(lastIndex, match.index) });
      }
      blocks.push({ type: 'code', language: match[1] || '', content: match[2] });
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < content.length) {
      blocks.push({ type: 'text', content: content.slice(lastIndex) });
    }

    return blocks;
  }, [content]);

  // Memoize the markdown components configuration
  const markdownComponents = useMemo(() => ({
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      const language = match ? match[1] : '';
      
      return inline ? (
        <code
          className="font-mono text-sm bg-[#2d2d2d] px-1 py-0.5 rounded text-[#e6e6e6]"
          {...props}
        >
          {children}
        </code>
      ) : (
        <SyntaxHighlighter
          language={language}
          style={vscDarkPlus}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      );
    },
    img({ src, alt, ...props }) {
      return (
        <img 
          src={src} 
          alt={alt || ''} 
          className="max-w-full h-auto rounded-lg my-2"
          loading="lazy"
          {...props}
        />
      );
    },
    a({ children, ...props }) {
      return (
        <a 
          {...props} 
          className="text-orange-500 underline hover:text-orange-600" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
    ul({ children, ...props }) {
      return <ul className="list-disc pl-6 my-2" {...props}>{children}</ul>;
    },
    ol({ children, ...props }) {
      return <ol className="list-decimal pl-6 my-2" {...props}>{children}</ol>;
    },
    p({ children, ...props }) {
      return <p className="my-2" {...props}>{children}</p>;
    },
    strong({ children, ...props }) {
      return <strong className="font-bold" {...props}>{children}</strong>;
    },
    em({ children, ...props }) {
      return <em className="italic" {...props}>{children}</em>;
    },
    blockquote({ children, ...props }) {
      return (
        <blockquote 
          className="border-l-4 border-gray-500 pl-4 my-2 italic" 
          {...props}
        >
          {children}
        </blockquote>
      );
    }
  }), []);

  return (
    <div className="text-left flex flex-col items-start">
      <div className="bg-[#1a1a1a] p-4 rounded-lg max-w-[90%] overflow-x-auto inline-block mb-2">
        {codeBlocks.map((block, index) => (
          block.type === 'text' ? (
            <ReactMarkdown
              key={`text-${index}`}
              className="prose prose-invert max-w-none select-text"
              components={markdownComponents}
            >
              {block.content}
            </ReactMarkdown>
          ) : (
            <CollapsibleTile
              key={`code-${index}`}
              content={block.content}
              isCodeBlock={true}
              language={block.language}
              onToggle={onTileToggle}
            />
          )
        ))}
      </div>

      {files.length > 0 && (
        <div className="flex flex-wrap mt-2 w-full">
          {files.map((file, index) => (
            <CollapsibleTile
              key={`file-${index}`}
              name={file.name}
              content={file.content}
              onToggle={onTileToggle}
            />
          ))}
        </div>
      )}

      {pastes.length > 0 && (
        <div className="flex flex-wrap mt-2 w-full">
          {pastes.map((paste, index) => (
            <CollapsibleTile
              key={`paste-${index}`}
              name={`Paste ${index + 1}`}
              content={paste.content}
              onToggle={onTileToggle}
            />
          ))}
        </div>
      )}
    </div>
  );
});

AssistantMessage.propTypes = {
  content: PropTypes.string.isRequired,
  files: PropTypes.array,
  pastes: PropTypes.array,
  onTileToggle: PropTypes.func,
};

export default AssistantMessage;
