// src/components/Pricing/FeatureList.js
import React from 'react';
import { Check, X } from 'lucide-react';

const FeatureList = ({ features }) => {
  return (
    <ul className="mt-6 space-y-4">
      {features.map((feature) => (
        <li 
          key={feature.name}
          className="flex items-center gap-3 text-sm"
        >
          {feature.included ? (
            <Check className="w-5 h-5 text-orange-500" />
          ) : (
            <X className="w-5 h-5 text-gray-500" />
          )}
          <span className={feature.included ? 'text-gray-200' : 'text-gray-400'}>
            {feature.name}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default FeatureList;
