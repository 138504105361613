// src/components/Pricing/BillingToggle.js
import React from 'react';

const BillingToggle = ({ billingCycle, onChange }) => {
  return (
    <div className="flex items-center justify-center gap-4 mt-6">
      <button
        onClick={() => onChange('monthly')}
        className={`px-4 py-2 rounded-lg transition-all duration-200 ${
          billingCycle === 'monthly' 
            ? 'bg-[#2a2a2a] text-white' 
            : 'text-gray-400 hover:text-white'
        }`}
      >
        Monthly
      </button>
      <div className="relative">
        <button
          onClick={() => onChange('yearly')}
          className={`px-4 py-2 rounded-lg transition-all duration-200 ${
            billingCycle === 'yearly' 
              ? 'bg-[#2a2a2a] text-white' 
              : 'text-gray-400 hover:text-white'
          }`}
        >
          Yearly
        </button>
        <span className="absolute -top-4 right-0 text-xs text-orange-500 font-medium">
          Save 20%
        </span>
      </div>
    </div>
  );
};

export default BillingToggle;
