// src/utils/utils.js

/**
 * Adjusts the height of a textarea based on its content.
 * @param {React.RefObject<HTMLTextAreaElement>} ref
 */
export const adjustTextareaHeight = (ref) => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };
  
  /**
   * Sanitizes a file name by removing invalid characters.
   * @param {string} name
   * @returns {string}
   */
  export const sanitizeFileName = (name) => {
    return name.replace(/[^a-zA-Z0-9-_\.]/g, '');
  };
  
  /**
   * Calculates total tokens from a conversation.
   * @param {Array} conversation
   * @returns {number}
   */
  export const calculateTokens = (conversation) => {
    return conversation.reduce((sum, msg) => sum + (msg.role === 'user' ? msg.content.length : 0) + (msg.role === 'assistant' ? msg.content.length : 0), 0);
  };
  
  /**
   * Generates a color from a palette based on index.
   * @param {number} index
   * @returns {string}
   */
  export const getColor = (index) => {
    const palette = [
      '#8884d8',
      '#82ca9d',
      '#ffc658',
      '#ff8042',
      '#8dd1e1',
      '#a4de6c',
      '#d0ed57',
      '#a28dd8',
      '#ca82ca',
      '#c65865',
      '#8042ff',
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
    ];
    return palette[index % palette.length];
  };