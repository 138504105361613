// src/services/api.js
import config from '../config';
export const API_BASE_URL = config.apiUrl;

/**
 * Handles the API response, parsing JSON and handling errors.
 * Also processes any message field in the JSON response.
 * 
 * @param {Response} response - The fetch Response object
 * @returns {Promise<Object|string>} - Parsed data or text content
 * @throws {Error} - If there's an error in the response or parsing
 */
const handleResponse = async (response) => {
  const text = await response.text();
  try {
    const data = JSON.parse(text);
    if (!response.ok) {
      // Create an error object that includes all relevant fields
      const errorData = {
        error: data.error || 'An unknown error occurred',
        message: data.message || null,
        code: data.code || null
      };
      throw errorData; // Throw the entire error object
    }
    // Handle message field if present
    if (data.message) {
      console.log('Message from server:', data.message);
    }
    return data;
  } catch (error) {
    if (response.ok) {
      return text;
    }
    // If it's our custom error object, throw it directly
    if (error.error && error.message) {
      throw error;
    }
    throw new Error(error.message || 'Failed to parse response');
  }
};

/**
 * Saves a conversation to the server.
 * @param {string} threadName - The name of the thread
 * @param {Array} messages - The messages in the conversation
 * @param {string|null} threadId - The ID of the thread (null for new threads)
 * @param {string} selectedModel - The selected AI model
 * @returns {Promise} - The server response
 */
export const saveConversation = async (threadName, messages, threadId = null, selectedModel) => {
  const response = await fetch(`${API_BASE_URL}/save_conversation`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      thread_id: threadId,
      thread_name: threadName || 'Untitled Chat',
      messages,
      paste_counter: messages.pastes?.length || 0,
      selected_model: selectedModel,
    }),
  });
  return handleResponse(response);
};

/**
 * Generates a concise chat title based on the initial query.
 * Includes enhanced error handling, logging, and fallback behavior.
 * 
 * @param {string} initialQuery - The initial user query.
 * @returns {Promise<string>} - A chat title (max 5 words) or fallback title.
 */
export const generateChatTitle = async (initialQuery) => {
  console.log('generateChatTitle: Starting with full configuration:', {
    initialQuery: initialQuery.substring(0, 100) + '...',
    API_BASE_URL,
    timestamp: new Date().toISOString()
});

  // System prompt for title generation
  const systemPrompt = `Your job is to create a short and safe chat title for the thread based on the initial query. 
    Requirements:
    - Maximum 5 words
    - Avoid any potentially offensive or inappropriate language
    - Replace inappropriate content with positive alternatives
    - Make it friendly and welcoming
    - Keep it concise and clear
    - Focus on the main topic
    - Don't include sensitive information
    
    Example good titles:
    - "Code Review Discussion"
    - "Python Learning Help"
    - "Website Design Planning"
    
    Example bad titles:
    - "Complex Discussion About Multiple Topics" (too long)
    - "????" (too vague)
    - "Query About Something" (not specific enough)
    
    Generate a title for this query.`;

    const messagesForTitle = [
      { role: 'user', content: `${systemPrompt}\n\n${initialQuery}` },
  ];

  try {
      console.log('generateChatTitle: Preparing request with payload:', {
          messages: messagesForTitle,
          model: 'gemini-1.5-flash-8b',
          is_title_generation: true
      });
      
      const response = await fetch(`${API_BASE_URL}/chat`, {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              messages: messagesForTitle,
              model: 'gemini-1.5-flash-8b',
              is_title_generation: true,
          }),
      });

      console.log('generateChatTitle: Raw response:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries(response.headers.entries())
      });

      if (!response.ok) {
          console.error('generateChatTitle: API request failed:', {
              status: response.status,
              statusText: response.statusText,
              body: await response.text()
          });
          return 'New Chat';
      }

      const data = await response.json();
      console.log('generateChatTitle: Parsed API response:', data);

      let title = '';
      if (data?.choices?.[0]?.message?.content) {
          title = data.choices[0].message.content.trim();
          console.log('generateChatTitle: Successfully extracted title:', title);
      } else {
          console.warn('generateChatTitle: Unexpected response structure:', data);
          title = 'New Chat';
      }

      // Clean and format the title
      const cleanTitle = title
          .replace(/[^\w\s-]/g, '')
          .trim();

      if (!cleanTitle) {
          console.warn('generateChatTitle: Title became empty after cleaning');
          return 'New Chat';
      }

      // Limit to 5 words
      const words = cleanTitle.split(/\s+/);
      const finalTitle = words.length > 5 ? words.slice(0, 5).join(' ') : cleanTitle;
      
      console.log('generateChatTitle: Final processed title:', {
          original: title,
          cleaned: cleanTitle,
          final: finalTitle
      });
      
      return finalTitle;

  } catch (error) {
      console.error('generateChatTitle: Error generating title:', {
          error: error.message,
          stack: error.stack,
          initialQuery: initialQuery.substring(0, 100) + '...'
      });
      return 'New Chat';
  }
};

export const fetchUsername = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/username`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await handleResponse(response); // Return JSON response
  } catch (error) {
    console.error('API: Error fetching username:', error);
      throw error;
  }
};

export const getConversations = async (loadType = 'initial', params = {}) => {
  try {
    console.log('API: getConversations called with:', {
      loadType,
      params,
      timestamp: new Date().toISOString()
    });

    const queryParams = new URLSearchParams({
      type: loadType,
      limit: params.limit || '50',
      ...(params.offset && { offset: params.offset.toString() }),
      ...(params.thread_id && { thread_id: params.thread_id.toString() })
    });

    const response = await fetch(`${API_BASE_URL}/get_conversations?${queryParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    // Handle different response formats based on loadType
    if (loadType === 'thread' && params.thread_id) {
      // If it's a single thread request, return messages directly
      return data;
    } else {
      // For paginated responses, extract threads array from wrapper object
      const threads = data.threads || data; // Handle both new and old response formats
      
      console.log('API: Successful response:', {
        loadType,
        threadCount: Array.isArray(threads) ? threads.length : 'N/A',
        offset: params.offset,
        hasMore: data.pagination?.has_more ?? (Array.isArray(threads) && threads.length >= (params.limit || 50)),
        pagination: data.pagination
      });

      // Return just the threads array to maintain compatibility with existing code
      return threads;
    }

  } catch (error) {
    console.error('API: Error in getConversations:', error);
    throw error;
  }
};

export const checkAuth = async () => {
  const response = await fetch(`${API_BASE_URL}/check-auth`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  await handleResponse(response);
};

export const deleteThread = async (threadId) => {
  const response = await fetch(`${API_BASE_URL}/delete_conversation/${threadId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return handleResponse(response);
};

/**
 * Fetches custom instructions, last selected model, experimental features state, and sidebar position
 * for the authenticated user.
 * 
 * @returns {Promise<Object>} Object containing user preferences including sidebar position
 */
export const fetchCustomInstructions = async () => {
  console.log('Fetching user preferences from API...');
  const response = await fetch(`${API_BASE_URL}/get_custom_instructions`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await handleResponse(response);
  console.log('API response for user preferences:', {
    customInstructions: data.instructions,
    lastModel: data.last_model,
    experimentalFeatures: data.experimental_features,
    sidebarPosition: data.sidebar_position || 'left' // Provide default if not set
  });
  return {
    ...data,
    sidebarPosition: data.sidebar_position || 'left' // Ensure sidebarPosition is always returned
  };
};

/**
 * Saves custom instructions, last selected model, experimental features state, and sidebar position 
 * for the authenticated user.
 * 
 * @param {string} instructions - The custom instructions to save
 * @param {string} lastModel - The last selected model
 * @param {boolean} experimentalFeatures - The state of experimental features
 * @param {string} sidebarPosition - The position of the sidebar ('left' or 'right')
 * @returns {Promise<Object>} The response from the server
 */
export const saveCustomInstructions = async (instructions, lastModel, experimentalFeatures, sidebarPosition) => {
  // Validate sidebar position
  if (sidebarPosition !== 'left' && sidebarPosition !== 'right') {
    throw new Error('Invalid sidebar position. Must be either "left" or "right"');
  }

  const response = await fetch(`${API_BASE_URL}/save_custom_instructions`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      instructions: instructions?.trim() || '',
      last_model: lastModel,
      experimental_features: experimentalFeatures,
      sidebar_position: sidebarPosition,
    }),
  });
  
  const data = await handleResponse(response);
  console.log('Saved user preferences:', {
    instructions: instructions?.trim() || '',
    lastModel,
    experimentalFeatures,
    sidebarPosition
  });
  
  return data;
};
export const getDashboardData = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/dashboard_data`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Dashboard data received:', data);
    return data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const updateThreadName = async (threadId, newName) => {
  const response = await fetch(`${API_BASE_URL}/update_thread_name/${threadId}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: newName }),
  });
  return handleResponse(response);
};

/**
 * Shares a thread and returns the public sharing URL
 * @param {string} threadId - The ID of the thread to share
 * @returns {Promise<Object>} Object containing share URL and public ID
 */
export const shareThread = async (threadId) => {
  try {
    console.log('API: Sharing thread:', threadId);
    
    const response = await fetch(`${API_BASE_URL}/share_thread/${threadId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('API: Thread shared successfully:', data);
    
    return {
      shareUrl: data.share_url,
      publicId: data.public_id
    };
  } catch (error) {
    console.error('API: Error sharing thread:', error);
    throw error;
  }
}

/**
 * Fetches a shared thread by its public ID
 * @param {string} publicId - The public ID of the shared thread
 * @returns {Promise<Object>} The shared thread data
 */
export const getSharedThread = async (publicId) => {
  try {
    console.log('API: Fetching shared thread:', publicId);
    
    const response = await fetch(`${API_BASE_URL}/shared/${publicId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('API: Shared thread fetched successfully:', data);
    
    return data;
  } catch (error) {
    console.error('API: Error fetching shared thread:', error);
    throw error;
  }
}
