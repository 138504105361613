// src/components/Pricing/PricingTierCard.js
import React from 'react';
import FeatureList from './FeatureList';
import CheckoutButton from './CheckoutButton';

const PricingTierCard = ({ tier, billingCycle }) => {
    const getPrice = () => {
      if (tier.monthlyPrice === null && tier.yearlyPrice === null) return null;
      return billingCycle === 'yearly' ? tier.yearlyPrice : tier.monthlyPrice;
    };

    // Get the appropriate productId based on billing cycle and tier
    const getProductId = () => {
      const productIds = {
        'Personal': {
          monthly: 'price_1QMIhgA28LivcWWoJ0BjaO6d',
          yearly: 'price_1QMIhgA28LivcWWoVBw5STej'
        },
        'Personal+': {
          monthly: 'price_1QMIhfA28LivcWWofeWPtVwO',
          yearly: 'price_1QMIhfA28LivcWWofW0iCW70'
        },
        'Pro': {
          monthly: 'price_1QMIhdA28LivcWWoIZheXZdh',
          yearly: 'price_1QMIhdA28LivcWWoaX2QH2lR'
        },
        'Team': {
          monthly: 'price_1QMIhcA28LivcWWogr8gTBEu',
          yearly: 'price_1QMIhcA28LivcWWoRLmHcgQc'
        },
        'Team+': {
          monthly: 'price_1QMIhbA28LivcWWoHt6KtTIn',
          yearly: 'price_1QMIhbA28LivcWWoJ1k4lVrc'
        }
      };

      return productIds[tier.name]?.[billingCycle];
    };
  
    return (
      <div
        className={`
          relative rounded-xl bg-[#2a2a2a] border 
          transition-all duration-300
          ${tier.highlight 
            ? 'border-orange-500 shadow-lg shadow-orange-500/10' 
            : 'border-[#3a3a3a] hover:border-[#4a4a4a]'
          }
        `}
      >
        {tier.highlight && (
          <div className="absolute -top-4 left-0 right-0 flex justify-center">
            <span className="bg-orange-500 text-white px-3 py-1 rounded-full text-sm">
              Most Popular
            </span>
          </div>
        )}
  
        <div className="p-6">
          <div className="flex items-center gap-3 mb-4">
            {tier.icon}
            <h2 className="text-xl font-bold">{tier.name}</h2>
          </div>
  
          <div className="mb-6">
            {getPrice() === null ? (
              <span className="text-3xl font-bold">Custom</span>
            ) : (
              <>
                <span className="text-3xl font-bold">${getPrice()}</span>
                <span className="text-gray-400 ml-2">/mo</span>
                {billingCycle === 'yearly' && (
                  <span className="text-sm text-orange-500 ml-2">
                    (billed yearly)
                  </span>
                )}
              </>
            )}
            <p className="text-gray-400 mt-2">{tier.description}</p>
          </div>
  
          {/* Show contact sales button for Enterprise tier, Stripe checkout for others */}
          {tier.name === 'Enterprise' ? (
            <button
              onClick={() => window.location.href = 'mailto:henosischat@gmail.com'}
              className={`
                w-full py-3 px-4 rounded-lg font-medium
                transition-all duration-200
                ${tier.buttonVariant === 'primary'
                  ? 'bg-orange-500 hover:bg-orange-600 text-white'
                  : 'bg-[#3a3a3a] hover:bg-[#404040] text-gray-200'
                }
              `}
            >
              {tier.buttonText}
            </button>
          ) : (
            <CheckoutButton 
              productId={getProductId()} 
            />
          )}
  
          <FeatureList features={tier.features} />
        </div>
      </div>
    );
  };
  
  export default PricingTierCard;
