// src/components/Common/PastesPreview.js

import React from 'react';
import { X } from 'lucide-react';

/**
 * Component to preview pastes in the message input area.
 *
 * @param {Array} pastes - Array of paste objects { content: string, fileName: string }
 * @param {Function} onDeletePaste - Function to delete a paste by index
 */
const PastesPreview = ({ pastes, onDeletePaste }) => {
  return (
    <div className="flex flex-wrap p-2 border-b border-gray-600">
      {pastes.map((paste, index) => (
        <button
          key={index}
          className="bg-[#1a1a1a] text-xs px-2 py-1 rounded mr-1 mb-1 hover:bg-[#232323] flex items-center transition-colors"
          title={paste.fileName ? `${paste.fileName}` : `Paste ${index + 1}`}
          onClick={() => onDeletePaste(index)}
        >
          {paste.fileName || `Paste ${index + 1}`} <X size={12} className="ml-1" />
        </button>
      ))}
    </div>
  );
};

export default PastesPreview;