import { useState, useRef, useCallback } from 'react';
import { saveConversation, generateChatTitle, API_BASE_URL } from '../services/api';
import config from '../config';

/**
 * Custom hook to manage chat messages and related actions.
 * Handles message sending, thread management, and API interactions.
 * 
 * @param {Object} params - Hook parameters
 * @param {Map} params.threads - Map of all threads
 * @param {Function} params.setThreads - Function to update threads Map
 * @param {string} params.currentThreadId - ID of currently selected thread
 * @param {Function} params.onLogout - Callback for logout action
 * @param {Array} params.pastes - Array of pasted content
 * @param {Function} params.setPastes - Function to update pastes
 * @param {string} params.customInstructions - Custom system instructions
 * @param {string} params.currentlySelectedModel - Currently selected AI model
 * @returns {Object} Chat state and actions
 */
const useChat = ({
  threads,
  setThreads,
  threadOrder,
  setThreadOrder,
  currentThreadId,
  setCurrentThreadId,
  onLogout,
  pastes,
  setPastes,
  customInstructions,
  currentlySelectedModel,
}) => {
  console.log('useChat: Initializing with params:', {
    hasThreads: Boolean(threads),
    currentThreadId,
    threadCount: threads?.size,
    model: currentlySelectedModel
  });

  const [inputMessage, setInputMessage] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  // Get current thread and its messages
  const currentThread = threads?.get(currentThreadId);
  const messages = currentThread?.messages || [];

  console.log('useChat: Current thread state:', {
    threadId: currentThreadId,
    threadExists: Boolean(currentThread),
    messageCount: messages.length,
    threadName: currentThread?.name
  });

  /**
   * Handles saving thread data to the backend
   */
  const handleThreadSave = useCallback(async (threadName, messageArray, threadId, isNewThread = false) => {
    console.log('useChat: Saving thread:', { 
        threadId, 
        isNewThread, 
        messageCount: messageArray.length,
        model: currentlySelectedModel,
        isTemporaryId: typeof threadId === 'string' && threadId.startsWith('temp-')
    });

    try {
        // If threadId is temporary or null, treat as new thread
        const shouldCreateNew = isNewThread || !threadId || (typeof threadId === 'string' && threadId.startsWith('temp-'));
        
        const response = await fetch(`${API_BASE_URL}/save_conversation`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                thread_id: shouldCreateNew ? null : threadId,
                thread_name: threadName || 'Untitled Chat',
                messages: messageArray,
                paste_counter: messageArray.pastes?.length || 0,
                selected_model: currentlySelectedModel,
            }),
        });

        if (!response.ok) {
            const error = await response.json();
            throw {
                error: error.error,
                message: error.message,
                code: error.code,
                status: response.status
            };
        }

        const savedData = await response.json();
        console.log('useChat: Save response:', savedData);
        return savedData;
    } catch (error) {
        console.error('useChat: Failed to save thread:', error);
        throw error;
    }
  }, [currentlySelectedModel]);

  /**
   * Handles sending a new message and receiving the assistant's response
   */
  const handleSendMessage = useCallback(async (inputMessageContent) => {
    if (!threads) {
        console.error('useChat: Threads map is not initialized');
        return;
    }

    console.log('useChat: Starting message send flow:', {
        threadId: currentThreadId,
        hasExistingThread: Boolean(currentThread),
        messageCount: messages.length,
        isFirstMessage: messages.length === 0,
        isTemporary: currentThread?.isTemporary,
        threadData: currentThread
    });

    if (!inputMessageContent.trim() && pastes.length === 0) {
        console.log('useChat: No message content to send');
        return;
    }

    let pastesContent = pastes.map((paste) => paste.content).join('\n');
    let newMessageContent = `${pastesContent}\n${inputMessageContent}`.trim();

    // Add custom instructions for first message in thread
    if (messages.length === 0 && customInstructions?.trim()) {
        console.log('useChat: Adding custom instructions to new message:', {
            instructionsLength: customInstructions.length,
            willGenerateTitle: true
        });
        newMessageContent = `${customInstructions.trim()}\n${newMessageContent}`;
    }

    const newMessage = {
        role: 'user',
        content: newMessageContent,
    };

    console.log('useChat: Prepared new message:', {
        contentLength: newMessageContent.length,
        hasCustomInstructions: Boolean(customInstructions?.trim()),
        isFirstMessage: messages.length === 0
    });

    // Update thread messages immediately
    const updatedMessages = [...messages, newMessage];
    setThreads(prev => {
        const updated = new Map(prev);
        if (currentThreadId) {
            updated.set(currentThreadId, {
                ...prev.get(currentThreadId),
                messages: updatedMessages
            });
        }
        return updated;
    });

    setIsGenerating(true);
    abortControllerRef.current = new AbortController();

    try {
        let threadId = currentThreadId;
        let threadName = currentThread?.name;
        const isTemporary = currentThread?.isTemporary || 
                           (typeof currentThreadId === 'string' && currentThreadId.startsWith('temp-'));

        // Handle new thread creation or first message in thread with default name
        if (isTemporary || (threadName === 'New Chat' && messages.length === 0)) {
          try {
              const chatTitle = await generateChatTitle(newMessage.content);
              console.log('useChat: Generated title for thread:', chatTitle);
              
              const savedThread = await handleThreadSave(
                  chatTitle,
                  [newMessage],
                  null,  // Pass null for new/temporary threads
                  true   // isNewThread = true
              );
      
              // Get permanent ID from response
              threadId = savedThread.thread_id;
              threadName = chatTitle;
      
              console.log('useChat: Converting temporary thread:', {
                  oldId: currentThreadId,
                  newId: threadId,
                  title: chatTitle,
                  savedThread
              });
      
              // Update threads Map
              setThreads(prev => {
                  const updated = new Map(prev);
                  if (isTemporary) {
                      updated.delete(currentThreadId);
                  }
                  updated.set(threadId, {
                      id: threadId,
                      name: chatTitle,
                      messages: [newMessage],
                      selectedModel: currentlySelectedModel,
                      isLoaded: true,
                      isTemporary: false
                  });
                  return updated;
              });
      
              // Update thread order and current ID
              setThreadOrder(prev => [threadId, ...prev.filter(id => id !== currentThreadId)]);
              setCurrentThreadId(threadId);
      
          } catch (error) {
              console.error('useChat: Error in thread creation:', {
                  error: error.message,
                  stack: error.stack,
                  threadId,
                  threadName,
                  errorObject: error
              });
              
              if (error.code === 'SUBSCRIPTION_REQUIRED') {
                  setError({
                      error: error.error,
                      message: error.message,
                      code: error.code
                  });
              } else {
                  setError({
                      error: 'Failed to create thread',
                      message: error.message || 'An unknown error occurred',
                      code: 'THREAD_CREATION_ERROR'
                  });
              }
              return;
          }
      }

        console.log('useChat: Sending message to API');
        const response = await fetch(`${config.apiUrl}/chat`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages: updatedMessages,
                model: currentlySelectedModel,
            }),
            signal: abortControllerRef.current.signal,
        });

        if (!response.ok) {
            if (response.status === 401) {
                console.log('useChat: Unauthorized, logging out');
                onLogout();
                return;
            }
            const errorData = await response.json();
            throw {
                error: errorData.error,
                message: errorData.message,
                code: errorData.code,
                status: response.status
            };
        }

        const data = await response.json();
        console.log('useChat: Received API response:', {
            responseLength: data.choices[0].message.content.length,
            model: currentlySelectedModel
        });

        const assistantMessage = {
            role: 'assistant',
            content: data.choices[0].message.content,
            model: currentlySelectedModel,
        };

        // Update thread with new messages
        const finalMessages = [...updatedMessages, assistantMessage];
        setThreads(prev => {
            const updated = new Map(prev);
            if (threadId) {
                updated.set(threadId, {
                    ...prev.get(threadId),
                    messages: finalMessages,
                    selectedModel: currentlySelectedModel,
                    isTemporary: false
                });
            }
            return updated;
        });

        // Save updated conversation
        console.log('useChat: Saving thread:', {
            threadId,
            messageCount: finalMessages.length,
            model: currentlySelectedModel,
            isTemporary: false
        });

        const savedThread = await handleThreadSave(
            threadName,
            finalMessages,
            threadId,
            false // Not a new thread at this point
        );

        console.log('useChat: Thread saved successfully:', savedThread);

        // Clear pastes after successful message
        setPastes([]);

    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('useChat: Request was aborted');
        } else {
            console.error('useChat: Error in message handling:', {
                error: error.error || error.message,
                code: error.code,
                phase: 'message processing',
                threadId: currentThreadId,
                fullError: error
            });

            // If it's an API error response with subscription info
            if (error.code === 'SUBSCRIPTION_REQUIRED') {
                setError({
                    error: error.error,
                    message: error.message,
                    code: error.code
                });
            } 
            // For API errors that came through response.json()
            else if (error.error) {
                setError({
                    error: error.error,
                    message: error.message || error.error,
                    code: error.code || 'API_ERROR'
                });
            }
            // For native Error objects or other errors
            else {
                setError({
                    error: 'Failed to process request',
                    message: error.message || 'An unknown error occurred',
                    code: 'INTERNAL_ERROR'
                });
            }
        }
    } finally {
        setIsGenerating(false);
        abortControllerRef.current = null;
    }
  }, [
    currentThreadId,
    currentThread,
    threads,
    messages,
    pastes,
    customInstructions,
    setThreads,
    setThreadOrder,
    setCurrentThreadId,
    currentlySelectedModel,
    onLogout,
    setPastes,
    handleThreadSave,
    setError,
    generateChatTitle
  ]);

  /**
   * Stops the ongoing message generation
   */
  const handleStopGeneration = useCallback(() => {
    console.log('useChat: Stopping message generation');
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsGenerating(false);
    }
  }, []);

  return {
    messages,
    inputMessage,
    setInputMessage,
    isGenerating,
    error,
    handleSendMessage,
    handleStopGeneration,
    setError,
  };
};

export default useChat;
