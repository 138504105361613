// PasswordReset.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../services/api';

const PasswordReset = ({ onLogin, onSwitchToRegister }) => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Get token and email from URL if they exist
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const emailFromUrl = searchParams.get('email');

  useEffect(() => {
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, [emailFromUrl]);

  // Styling classes
  const formClasses = "bg-[#2a2a2a] p-8 rounded-lg shadow-xl w-full max-w-md mx-auto border border-[#3a3a3a]";
  const inputClasses = "w-full p-3 mb-4 bg-[#1a1a1a] border border-[#3a3a3a] rounded-lg text-white text-sm focus:border-orange-500 focus:ring-1 focus:ring-orange-500 transition-all duration-200 outline-none";
  const buttonClasses = "w-full py-3 px-4 rounded-lg mb-3 transition-all duration-200 font-medium text-sm";
  const errorClasses = "bg-red-500/10 border border-red-500/50 text-red-400 p-4 rounded-lg mb-4 text-sm";
  const successClasses = "bg-green-500/10 border border-green-500/50 text-green-400 p-4 rounded-lg mb-4 text-sm";
  const linkClasses = "text-orange-500 hover:text-orange-400 text-sm transition-colors duration-200";

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);
  
    console.log('Requesting password reset for email:', email);
  
    try {
      console.log('Making request to:', `${API_BASE_URL}/request_password_reset`);
      
      const response = await fetch(`${API_BASE_URL}/request_password_reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Response data:', data);
  
      if (response.ok) {
        console.log('Password reset request successful');
        setSuccess('Reset instructions have been sent to your email. Please check your inbox.');
      } else {
        console.error('Password reset request failed:', data.error);
        setError(data.error || 'Failed to request password reset');
      }
    } catch (err) {
      console.error('Error requesting password reset:', err);
      setError('An error occurred while requesting password reset');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailFromUrl,
          reset_token: token,
          new_password: newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Password has been reset successfully. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setError(data.error || 'Failed to reset password');
      }
    } catch (err) {
      setError('An error occurred while resetting password');
    } finally {
      setIsLoading(false);
    }
  };

  // If we have a token and email, show the reset password form
  if (token && emailFromUrl) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#1a1a1a] px-4 py-8">
        <div className={formClasses}>
          <h2 className="text-2xl font-bold mb-6 text-white text-center">Reset Password</h2>
          
          {error && <div className={errorClasses}>{error}</div>}
          {success && <div className={successClasses}>{success}</div>}
          
          <form onSubmit={handleResetPassword}>
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={inputClasses}
              required
              minLength={8}
            />
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={inputClasses}
              required
              minLength={8}
            />
            <button
              type="submit"
              disabled={isLoading}
              className={`${buttonClasses} ${
                isLoading ? 'bg-orange-400' : 'bg-orange-500 hover:bg-orange-600'
              } text-white`}
            >
              {isLoading ? 'Resetting Password...' : 'Reset Password'}
            </button>
          </form>
        </div>
      </div>
    );
  }

  // Show the initial password reset request form
  return (
    <div className="flex items-center justify-center min-h-screen bg-[#1a1a1a] px-4 py-8">
      <div className={formClasses}>
        <h2 className="text-2xl font-bold mb-6 text-white text-center">Reset Password</h2>
        
        {error && <div className={errorClasses}>{error}</div>}
        {success && <div className={successClasses}>{success}</div>}
        
        <form onSubmit={handleRequestReset}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={inputClasses}
            required
          />
          <button
            type="submit"
            disabled={isLoading}
            className={`${buttonClasses} ${
              isLoading ? 'bg-orange-400' : 'bg-orange-500 hover:bg-orange-600'
            } text-white`}
          >
            {isLoading ? 'Sending Reset Link...' : 'Send Reset Link'}
          </button>
        </form>

        <div className="mt-6 text-center space-y-2">
          <button
            onClick={() => navigate('/login')}
            className={`${buttonClasses} bg-[#3a3a3a] hover:bg-[#404040] text-gray-300 hover:text-white`}
          >
            Back to Login
          </button>
          
          <div className="text-gray-400 text-sm">
            Don't have an account?{' '}
            <button
              onClick={onSwitchToRegister}
              className={linkClasses}
            >
              Register here
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
