import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

/**
 * Converts URLs in text to clickable links
 * @param {string} text - Text that may contain URLs
 * @returns {Array} Array of text and link elements
 */
const processMessageWithLinks = (text) => {
  if (!text) return [];
  
  // URL regex pattern - modified to be more precise
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  
  let lastIndex = 0;
  const elements = [];
  let match;

  // Use regex.exec to iterate through matches
  while ((match = urlPattern.exec(text)) !== null) {
    // Add text before the URL
    if (match.index > lastIndex) {
      elements.push(
        <span key={`text-${lastIndex}`}>
          {text.substring(lastIndex, match.index)}
        </span>
      );
    }

    // Add the URL as a link
    elements.push(
      <a
        key={`link-${match.index}`}
        href={match[0]}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-200 hover:text-blue-100 underline"
      >
        {match[0]}
      </a>
    );

    lastIndex = match.index + match[0].length;
  }

  // Add any remaining text after the last URL
  if (lastIndex < text.length) {
    elements.push(
      <span key={`text-${lastIndex}`}>
        {text.substring(lastIndex)}
      </span>
    );
  }

  return elements;
};

const ErrorPopup = ({ error, onClose }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [serverMessage, setServerMessage] = useState('');

  useEffect(() => {
    const handleError = async () => {
      console.group('ErrorPopup - handleError');
      console.log('Initial error object:', error);

      try {
        // Case 1: Response object (from fetch)
        if (error instanceof Response) {
          console.log('Handling Response type error');
          const text = await error.text();
          console.log('Raw error response text:', text);
          
          try {
            const data = JSON.parse(text);
            console.log('Parsed error data:', data);
            
            // Handle subscription errors specifically
            if (data.code === 'SUBSCRIPTION_REQUIRED') {
              setErrorMessage('Subscription Required');
              setServerMessage(data.message);
              console.log('Subscription error detected:', data.message);
              return;
            }
            
            setErrorMessage(data.error || 'An unknown error occurred');
            if (data.message) {
              setServerMessage(data.message);
            }
          } catch (parseError) {
            console.error('Failed to parse error response:', parseError);
            setErrorMessage(text || 'Failed to parse error response');
          }
        }
        // Case 2: Error object with specific properties
        else if (error && typeof error === 'object') {
          console.log('Handling object type error');
          
          setErrorMessage(error.error || 'An unknown error occurred');
          if (error.message) {
            setServerMessage(error.message);
          }
          
          console.log('Error properties:', {
            error: error.error,
            message: error.message,
            code: error.code
          });
        }
        // Case 3: String error
        else if (typeof error === 'string') {
          console.log('Handling string type error');
          setErrorMessage(error);
        }
        // Case 4: Fallback
        else {
          console.log('Handling unknown type error');
          setErrorMessage('An unknown error occurred');
        }
      } catch (handlingError) {
        console.error('Error while handling error:', handlingError);
        setErrorMessage('Failed to process error message');
      } finally {
        console.groupEnd();
      }
    };

    handleError();
  }, [error]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50 z-40 pointer-events-none"></div>
      
      {/* Popup */}
      <div className="bg-red-600 text-white p-6 rounded-lg z-50 pointer-events-auto w-11/12 max-w-md mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Error</h3>
          <button 
            onClick={onClose} 
            aria-label="Close Error Popup"
            className="hover:bg-red-700 rounded-full p-1"
          >
            <X size={20} />
          </button>
        </div>
        <p className="mb-2">{errorMessage}</p>
        {serverMessage && (
          <p className="mt-2 text-sm border-t border-red-400 pt-2">
            {processMessageWithLinks(serverMessage)}
          </p>
        )}
      </div>
    </div>
  );
};

export default ErrorPopup;
