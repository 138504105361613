import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

/**
 * Header component specifically for the projects page
 * Includes logo, back button, and maintains consistent styling with main header
 */
const ProjectsHeader = () => {
  const navigate = useNavigate();

  return (
    <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30">
      <div className="flex items-center">
        <button
          onClick={() => navigate('/')}
          className="p-1.5 bg-orange-500 rounded hover:bg-orange-600 transition-colors mr-4"
          aria-label="Back to Chat"
        >
          <ArrowLeft size={15} />
        </button>
        <div className="flex items-center cursor-pointer" onClick={() => window.location.href = '/'}>
          <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
          <div className="text-lg font-bold text-white">henosis</div>
        </div>
      </div>
    </header>
  );
};

export default ProjectsHeader;