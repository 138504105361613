import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Component for displaying individual project cards
 */
const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(`/projects/${project.id}`)}
      className="bg-[#2c2c2c] p-6 rounded-lg hover:bg-[#3a3a3a] transition-colors w-full text-left mb-4 text-white"
    >
      <h3 className="text-xl font-semibold mb-2 text-white">{project.name}</h3>
      <p className="text-gray-400">{project.description}</p>
      <div className="mt-4 text-sm text-gray-500">
        Model: {project.model}
      </div>
    </button>
  );
};

export default ProjectCard;