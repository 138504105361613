import React, { useState, useEffect, useRef } from 'react';
import { Settings, MessageCircle, Plus, Menu, Share2, Clipboard, Check, ChevronDown, PanelLeft, PanelRight } from 'lucide-react';
import { shareThread } from '../services/api';
import useSettings from '../hooks/useSettings';

const Header = ({
  onSettingsToggle,
  onLogout,
  onNewChat,
  onToggleSidebar,
  onDashboardClick,
  currentThreadId,
  currentlySelectedModel,
  setCurrentlySelectedModel,
  sidebarPosition,
  setSidebarPosition,
  isSidebarOpen // Add isSidebarOpen prop
}) => {
  // State Management
  const [showShareModal, setShowShareModal] = useState(false);
  const [showShareConfirm, setShowShareConfirm] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [isSharing, setIsSharing] = useState(false);
  const [shareError, setShareError] = useState('');
  const [copied, setCopied] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Initialize settings hook
  const {
    customInstructions,
    handleSaveSettings,
    fetchSettings,
  } = useSettings({
    onLogout,
    defaultModel: currentlySelectedModel
  });

  // Fetch settings on mount
  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const models = [
    { value: 'gpt-4o-mini', label: 'GPT-4O Mini' },
    { value: 'claude-3-5-haiku-20241022', label: 'Claude 3.5 Haiku 2024-10-22' },
    { value: 'claude-3-5-sonnet-20241022', label: 'Claude 3.5 Sonnet Latest' },
    { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus 2024-02-29' },
    { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku 2024-03-07' },
    { value: 'gemini-1.5-flash-8b', label: 'Gemini 1.5 Flash 8B' },
    { value: 'gemini-1.5-flash-latest', label: 'Gemini 1.5 Flash Latest' },
    { value: 'gemini-1.5-pro-latest', label: 'Gemini 1.5 Pro Latest' },
    { value: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash Exp' },
    { value: 'reka-edge-20240208', label: 'Reka Edge' },
    { value: 'reka-flash-20240904', label: 'Reka Flash' },
    { value: 'reka-core-20240904', label: 'Reka Core' },
     { value: 'grok-beta', label: 'Grok Beta' },
     { value: 'Meta-Llama-3.1-405B-Instruct', label: 'Meta Llama 3.1 405B Instruct' },
    { value: 'gpt-4o-2024-11-20', label: 'GPT-4O 2024-11-20' },
    { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
     { value: 'chatgpt-4o-latest', label: 'ChatGPT-4O Latest' },
     { value: 'deepseek-chat', label: 'DeepSeek Chat' },
     { value: 'o1-mini', label: 'O1 Mini' },
     { value: 'o1-preview', label: 'O1 Preview' },
  ];

  // Handle model selection with save
  const handleModelSelect = async (modelValue) => {
    try {
      setCurrentlySelectedModel(modelValue);
      setIsModelDropdownOpen(false);

      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: modelValue,
        sidebarPosition: sidebarPosition
      });
    } catch (error) {
      console.error('Failed to save model selection:', error);
    }
  };

  // Handle sidebar position toggle
  const handleSidebarPositionToggle = async (newPosition) => {
    try {
      setSidebarPosition(newPosition);
      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: currentlySelectedModel,
        sidebarPosition: newPosition
      });
    } catch (error) {
      console.error('Failed to save sidebar position:', error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Get current model label
  const getCurrentModelLabel = () => {
    const model = models.find(m => m.value === currentlySelectedModel);
    return model ? model.label : 'Select Model';
  };

  const handleShareClick = () => {
    if (!currentThreadId) {
      setShareError('Please select a thread to share');
      setShowShareModal(true);
      return;
    }
    setShowShareConfirm(true);
  };

  const handleConfirmedShare = async () => {
    setShowShareConfirm(false);
    setIsSharing(true);
    setShareError('');
    setShareUrl('');
    setCopied(false);
    
    try {
      const { shareUrl } = await shareThread(currentThreadId);
      setShareUrl(shareUrl);
      setShareError('');
    } catch (error) {
      console.error('Failed to share thread:', error);
      setShareError('Failed to share thread. Please try again.');
    } finally {
      setIsSharing(false);
      setShowShareModal(true);
    }
  };

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
      setShareError('Failed to copy URL to clipboard');
    }
  };

    // Share Confirmation Modal Component
  const ShareConfirmModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2a2a2a] p-6 rounded-lg shadow-xl max-w-sm w-full">
        <h2 className="text-lg font-bold mb-4 text-white">Share Thread</h2>
        <p className="mb-6 text-gray-300">
          Are you sure you want to share this thread? Anyone with the share link will be able to view this conversation.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowShareConfirm(false)}
            className="px-4 py-1.5 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmedShare}
            className="px-4 py-1.5 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );

  // Share Modal Component
  const ShareModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2a2a2a] p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold text-white">Share Thread</h2>
          <button
            onClick={() => setShowShareModal(false)}
            className="text-gray-400 hover:text-white transition-colors"
            aria-label="Close Modal"
          >
            ×
          </button>
        </div>
        
        {shareError ? (
          <div className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded text-red-400">
            {shareError}
          </div>
        ) : isSharing ? (
          <div className="mb-4 text-gray-300 flex items-center">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
            Generating share link...
          </div>
        ) : shareUrl ? (
          <>
            <div className="mb-4">
              <label className="block text-sm text-gray-400 mb-2">Share Link</label>
              <div className="flex items-center bg-[#1a1a1a] rounded border border-gray-700">
                <input
                  type="text"
                  value={shareUrl}
                  readOnly
                  className="flex-1 bg-transparent text-white p-2 outline-none"
                />
                <button
                  onClick={handleCopyUrl}
                  className={`px-4 py-2 flex items-center space-x-2 transition-colors ${
                    copied ? 'text-green-500' : 'text-orange-500 hover:text-orange-400'
                  }`}
                  aria-label="Copy URL"
                >
                  {copied ? (
                    <>
                      <Check size={16} />
                      <span className="text-sm">Copied!</span>
                    </>
                  ) : (
                    <>
                      <Clipboard size={16} />
                      <span className="text-sm">Copy</span>
                    </>
                  )}
                </button>
              </div>
            </div>
            <p className="text-gray-400 text-sm">
              Anyone with this link can view this thread
            </p>
          </>
        ) : (
          <p className="text-gray-300 mb-4">
            Share your conversation thread with others
          </p>
        )}
        
        <div className="flex justify-end mt-6">
          <button
            onClick={() => setShowShareModal(false)}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );


  return (
    <>
      <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30">
        {/* Left side */}
        <div className="flex items-center">
           {/* Mobile menu button - only show on small screens */}
          <button
            onClick={onToggleSidebar}
            className="lg:hidden p-1.5 bg-orange-500 rounded hover:bg-orange-600 transition-colors mr-2"
            aria-label="Toggle Sidebar"
          >
            <Menu size={15} />
          </button>
           {/* Desktop sidebar toggle - only show when sidebar is left positioned and not expanded*/}
          {sidebarPosition === 'left' && !isSidebarOpen && (
            <button
              onClick={onToggleSidebar}
                className="hidden lg:flex p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors mr-2"
              aria-label="Open Sidebar"
              title="Open Sidebar"
            >
              <PanelRight size={15} />
            </button>
          )}

          <div className="hidden lg:flex items-center cursor-pointer ml-2" onClick={() => window.location.href = '/'}>
            <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
            <div className="text-lg font-bold">henosis</div>
          </div>
        </div>

        {/* Center - Model Selector */}
        <div className="relative mx-4 min-w-[120px] max-w-[280px] flex-shrink" ref={dropdownRef}>
          <button
            onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
            className="w-full flex items-center justify-between px-4 py-2 bg-[#2a2a2a] rounded-lg hover:bg-[#3a3a3a] transition-colors"
            aria-label="Select Model"
            title="Select Model"
          >
            <span className="text-sm font-medium truncate pr-2">
              {getCurrentModelLabel()}
            </span>
            <ChevronDown 
              size={16} 
              className={`transform transition-transform duration-200 flex-shrink-0 ${isModelDropdownOpen ? 'rotate-180' : ''}`} 
            />
          </button>

          {isModelDropdownOpen && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-64 py-2 bg-[#2a2a2a] rounded-lg shadow-xl z-50 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full">
              {models.map((model) => (
                <button
                  key={model.value}
                  onClick={() => handleModelSelect(model.value)}
                  className={`w-full text-left px-4 py-2 text-sm hover:bg-[#3a3a3a] transition-colors ${
                    currentlySelectedModel === model.value ? 'bg-orange-500 text-white' : 'text-gray-200'
                  }`}
                  title={`Select ${model.label}`}
                >
                  {model.label}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Right side */}
        <div className="flex items-center space-x-2">
          <button
            onClick={handleShareClick}
            className={`p-1.5 rounded transition-colors ${
              isSharing 
                ? 'bg-gray-600 cursor-not-allowed' 
                : ' hover:bg-[#2a2a2a] text-gray-400 hover:text-white'
            }`}
            aria-label="Share Thread"
            title="Share Thread"
            disabled={isSharing}
          >
            <Share2 size={15} />
          </button>

          <button
            onClick={onNewChat}
             className="p-1.5 rounded  hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
            aria-label="New Chat"
            title="New Chat"
          >
            <MessageCircle size={15} className="relative">
              <Plus size={10} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </MessageCircle>
          </button>

          <button
            onClick={onSettingsToggle}
             className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
            aria-label="Settings"
            title="Settings"
          >
            <Settings size={15} />
          </button>

          {/* Desktop sidebar toggle - only show when sidebar is right positioned and not expanded*/}
          {sidebarPosition === 'right' && !isSidebarOpen &&(
              <button
                  onClick={onToggleSidebar}
                  className="hidden lg:flex p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
                  aria-label="Open Sidebar"
                  title="Open Sidebar"
              >
                  <PanelLeft size={15} />
              </button>
          )}
        </div>
      </header>

      {/* Modals */}

      {/* Share Confirmation Modal */}
      {showShareConfirm && <ShareConfirmModal />}

      {/* Share Modal */}
      {showShareModal && <ShareModal />}
    </>
  );
};

export default Header;
