import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../config';

/**
 * RegistrationForm Component
 * Handles user registration with email verification and prefill support
 * 
 * @param {Object} props
 * @param {Function} props.onRegister - Callback for successful registration
 * @param {Function} props.onSwitchToLogin - Callback to switch to login view
 */
const RegistrationForm = ({ onRegister, onSwitchToLogin }) => {
  const location = useLocation();
  const prefillEmail = location.state?.prefillEmail || '';

  const [registrationData, setRegistrationData] = useState({
    username: '',
    email: prefillEmail,
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Focus username field if email is prefilled
  useEffect(() => {
    if (prefillEmail) {
      const usernameInput = document.querySelector('input[name="username"]');
      if (usernameInput) {
        usernameInput.focus();
      }
    }
  }, [prefillEmail]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData(prevData => ({ ...prevData, [name]: value }));
    
    // Clear error when user starts typing
    if (error) setError('');
  };

  const validateForm = () => {
    if (registrationData.password !== registrationData.confirmPassword) {
      setError("Passwords don't match");
      return false;
    }

    if (registrationData.password.length < 6) {
      setError("Password must be at least 6 characters long");
      return false;
    }

    if (!registrationData.email.includes('@')) {
      setError("Please enter a valid email address");
      return false;
    }

    if (registrationData.username.length < 3) {
      setError("Username must be at least 3 characters long");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!validateForm()) return;
    
    setIsLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: registrationData.username,
          email: registrationData.email,
          password: registrationData.password,
        }),
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        if (data.requiresVerification) {
          setIsVerifying(true);
        } else {
          onRegister(data);
        }
      } else {
        if (data.error && typeof data.error === 'object') {
          const errorMessages = Object.entries(data.error)
            .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
            .join('; ');
          setError(errorMessages || 'Registration failed. Please try again.');
        } else {
          setError(data.error || 'Registration failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/verify_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: registrationData.email,
          verification_code: verificationCode,
        }),
        credentials: 'include',
      });

      const data = await response.json();
      
      if (response.ok) {
        onRegister(data);
      } else {
        setError(data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred during verification. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Shared style classes
  const formClasses = "bg-[#2a2a2a] p-8 rounded-lg shadow-xl w-full max-w-md mx-auto border border-[#3a3a3a]";
  const inputClasses = "w-full p-3 mb-4 bg-[#1a1a1a] border border-[#3a3a3a] rounded-lg text-white text-sm focus:border-orange-500 focus:ring-1 focus:ring-orange-500 transition-all duration-200 outline-none";
  const buttonClasses = "w-full py-3 px-4 rounded-lg mb-3 transition-all duration-200 font-medium text-sm relative";
  const errorClasses = "bg-red-500/10 border border-red-500/50 text-red-400 p-4 rounded-lg mb-4 text-sm";

  // Loading spinner component
  const LoadingSpinner = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-orange-500">
      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
    </div>
  );

  if (isVerifying) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#1a1a1a] px-4 py-8">
        <form onSubmit={handleVerificationSubmit} className={formClasses}>
          <h2 className="text-2xl font-bold mb-6 text-white text-center">Verify Email</h2>
          {error && <div className={errorClasses}>{error}</div>}
          <p className="text-gray-400 mb-4 text-sm text-center">
            Please enter the verification code sent to your email address
          </p>
          <input
            type="text"
            name="verificationCode"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className={inputClasses}
            required
          />
          <button 
            type="submit" 
            className={`${buttonClasses} bg-orange-500 hover:bg-orange-600 text-white`}
            disabled={isLoading}
          >
            {isLoading ? <LoadingSpinner /> : 'Verify'}
          </button>
        </form>
      </div>
    );
  }

  const emailInputClasses = `${inputClasses} ${
    prefillEmail ? 'border-orange-500/50 bg-orange-500/5' : ''
  }`;

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#1a1a1a] px-4 py-8">
      <form onSubmit={handleSubmit} className={formClasses}>
        <h2 className="text-2xl font-bold mb-6 text-white text-center">Register</h2>
        {error && <div className={errorClasses}>{error}</div>}
        
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={registrationData.username}
          onChange={handleInputChange}
          className={inputClasses}
          autoComplete="username"
          required
          minLength={3}
        />
        
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={registrationData.email}
          onChange={handleInputChange}
          className={emailInputClasses}
          autoComplete="email"
          required
        />
        
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={registrationData.password}
          onChange={handleInputChange}
          className={inputClasses}
          autoComplete="new-password"
          required
          minLength={6}
        />
        
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={registrationData.confirmPassword}
          onChange={handleInputChange}
          className={inputClasses}
          autoComplete="new-password"
          required
        />

        <button 
          type="submit" 
          className={`${buttonClasses} bg-orange-500 hover:bg-orange-600 text-white`}
          disabled={isLoading}
        >
          {isLoading ? <LoadingSpinner /> : 'Register'}
        </button>
        
        <button 
          type="button" 
          onClick={onSwitchToLogin} 
          className={`${buttonClasses} bg-[#3a3a3a] hover:bg-[#404040] text-gray-300 hover:text-white`}
          disabled={isLoading}
        >
          Back to Login
        </button>

        <p className="text-gray-400 text-xs text-center mt-4">
          By registering, you agree to our{' '}
          <a href="/terms" className="text-orange-500 hover:text-orange-400">
            Terms of Service
          </a>
          {' '}and{' '}
          <a href="/privacy" className="text-orange-500 hover:text-orange-400">
            Privacy Policy
          </a>
        </p>
      </form>
    </div>
  );
};

export default RegistrationForm;
