// CreditUsageWheel.js
import React from 'react';

/**
 * CreditUsageWheel Component
 * This component displays a circular progress bar showing the percentage of credits used
 * @param {number} totalCost - The total cost of usage
 * @param {number} loadedCredits - The amount of loaded credits
 */
const CreditUsageWheel = ({ totalCost, loadedCredits }) => {
  const percentage =
    loadedCredits > 0 ? Math.min((totalCost / loadedCredits) * 100, 100) : 0;
  const strokeDasharray = `${percentage}, 100`;

  return (
    <div className="relative w-16 h-16">
      <svg className="w-full h-full transform rotate-180" viewBox="0 0 36 36">
        <path
          d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke="#444"
          strokeWidth="3"
        />
        <path
          d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke="#4CAF50"
          strokeWidth="3"
          strokeDasharray={strokeDasharray}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-sm font-semibold">{percentage.toFixed(0)}%</span>
      </div>
    </div>
  );
};

export default CreditUsageWheel;